import * as yup from "yup";

export const loginSchema = yup.object().shape({
  password: yup.string().required("Password is required"),
  // .matches(
  //   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
  //   "Your password must be more than 8 characters long, should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric and 1 special character"
  // ),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email is required"),
});
