import React from "react";
import TopBar from "./topBar";
import SideBarAdmin from "./SideBarAdmin";
import SideBar from "./SideBarSuperAdmin";

const WrapperSu = (props) => {
  if (localStorage.getItem("role" === "superadmin")) {
    console.log("inside if");
  }
  return (
    <>
      <div className="relative min-h-screen md:flex">
        {localStorage.getItem("role") === "superadmin" ? (
          <SideBar />
        ) : (
          <SideBarAdmin />
        )}

        <div className=" flex-1 md:ml-60">
          <TopBar />
          <div className="p-3">
            <div className="bg-white shadow-lg w-full  z-40 p-9">
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WrapperSu;
