const AddonsComp = () => {
  return (
    <div className="flex flex-col p-5 rounded-3xl w-full  bg-gray-200 gap-3">
      <div className="flex flex-col md:flex-row justify-between">
        <h2 className="text-xl  font-bold">Custom Emojis</h2>
        <p className="text-green-500">Payment Recieved</p>
      </div>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum, officia
        similique deleniti tenetur vel dolore optio neque repellendus molestias
        ut, quidem quo sapiente laboriosam non. Sequi repellat ipsum molestias
        cumque?
      </p>
      <div className="flex flex-col md:flex-row md:items-center md:justify-between  gap-3">
        <p>
          Price: <span className="font-bold text-lg">$100/month</span>
        </p>
        <a
          className="p-3 simple-green rounded-lg w-40 text-center text-white"
          href=""
        >
          Edit Credentials
        </a>
      </div>
    </div>
  );
};

export default AddonsComp;
