import React from "react";
import SideBarAdmin from "./SideBarAdmin";
import TopBar from "./topBar";
import WrapperAdmin from "./WrapperAdmin";
import WrapperSu from "./WrapperSu";
const Wrapper = (props) => {
  return (
    <>
      {/* {localStorage.getItem(role === "superadmin") ? (
        <WrapperSu />
      ) : (
        <WrapperAdmin />
      )} */}
    </>
  );
};

export default Wrapper;
