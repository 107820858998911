import SearchBar from "../../../components/SearchBar";
import TableFooter from "../../../components/TableFooter";
import QuestionVectorsComp from "../../../components/QuestionVectorsComp";
import AddonsComp from "../../../components/AddonsComp";

const ViewAddons = () => {
  const handlePageClick = () => {};
  return (
    <div>
      <div className="flex flex-wrap gap-6 flex-row w-100 mt-3 justify-between items-center">
        <h1 className="text-4xl font-bold">Hotel Addons</h1>

        <SearchBar />
      </div>

      <div className=" items-center mt-3 grid grid-cols-1 md:grid-cols-2 gap-7">
        <AddonsComp />
        <AddonsComp />
        <AddonsComp />
        <AddonsComp />
        <AddonsComp />
        <AddonsComp />
        <AddonsComp />
        <AddonsComp />
        <AddonsComp />
      </div>

      <TableFooter handlePageClick={handlePageClick} />
    </div>
  );
};

export default ViewAddons;
