import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../images/FmbLogo.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import * as yup from "yup";
import { userSchema } from "../FormSchemas/RegistrationSchema";
import { useEffect, useState } from "react";
import axios from "axios";
import { Oval } from "react-loader-spinner";
const Registration = () => {
  const history = useHistory();
  const [industry, setIndustry] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    showIndustry();
  }, []);

  const success = (text) => {
    toast.success(text);
  };

  const notifyError = (text) => {
    toast.error(text);
  };

  const showIndustry = async (e) => {
    const url = `${process.env.REACT_APP_BASE_URL}/showIndustry`;
    await axios
      .get(url)

      .then((res) => {
        setIndustry(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const headers = {
    "Content-Type": "application/json",
  };
  const submitForm = async (data) => {
    console.log(data);
    const url = `${process.env.REACT_APP_BASE_URL}/signup`;
    setLoading(true);
    await axios
      .post(url, data, {
        headers: headers,
      })
      .then((res) => {
        console.log(res);
        console.log("Success !!");
        reset();
        setLoading(false);
        success(res.data.message);
        //  notify();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        notifyError(error.message);
      });
  };

  const handleChange = (e) => {
    console.log("select val", e.target.value);
  };
  return (
    <div className="flex flex-col gap-3 justify-content-center items-center">
      <img className="logo h-28 w-28" src={logo} alt="" />
      <div className="text-center">
        <h1 className="text-2xl md:text-3xl  font-bold">Sign Up</h1>
      </div>
      <form className=" md:w-1/4" onSubmit={handleSubmit(submitForm)}>
        <div className="flex flex-col">
          <input
            type="text"
            placeholder="Name"
            name="name"
            className="px-5 py-3 rounded-xl input-field bg-white rounded-0 col-md-3 mt-3"
            {...register("name")}
          />
          <p className="text-sm text-red-700 ml-3"> {errors.name?.message} </p>
        </div>

        <div className="flex flex-col">
          <input
            type="text"
            placeholder="Organization"
            name="company_name"
            className="px-5 py-3 rounded-xl input-field bg-white rounded-0 col-md-3 mt-3"
            {...register("company_name")}
          />
          <p className="text-sm text-red-700 ml-3">
            {" "}
            {errors.company_name?.message}{" "}
          </p>
        </div>

        <div className="flex flex-col">
          <input
            type="text"
            placeholder="Contact Number"
            name="contact_no"
            className="px-5 py-3 rounded-xl input-field bg-white rounded-0 col-md-3 mt-3"
            {...register("contact_no")}
          />
          <p className="text-sm text-red-700 ml-3">
            {" "}
            {errors.contact_no?.message}{" "}
          </p>
        </div>

        <div className="flex flex-col">
          <input
            type="email"
            placeholder="Email"
            name="email"
            className="px-5 py-3 rounded-xl input-field bg-white rounded-0 col-md-3 mt-3"
            {...register("email")}
          />
          <p className="text-sm text-red-700 ml-3"> {errors.email?.message} </p>
        </div>
        <div className="flex flex-col">
          <select
            className="px-3 py-3 rounded-xl input-field bg-white rounded-0 col-md-3 mt-3"
            id="grid-state"
            placeholder="Industry"
            name="type_id"
            {...register("type_id")}
            onChange={handleChange}
          >
            <option value="" disabled>
              Industry
            </option>
            {industry.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
          {/* <input
            type="text"
            placeholder="Industry"
            name="industry"
            className="px-5 py-3 rounded-xl input-field bg-white rounded-0 col-md-3 mt-3"
            {...register("industry")}
          /> */}
          <p className="text-sm text-red-700 ml-3">
            {" "}
            {errors.type_id?.message}{" "}
          </p>
        </div>

        <div className="flex flex-col justify-center text-center items-center">
          {/* <Link
            to="/"
            onClick={() => {
              history.push("/");
            }}
            type="submit"
            className="sign-in py-3 px-10 mt-7 text-center text-white rounded-xl"
          >
            Sign Up
          </Link> */}
          {/* <button
            className="sign-in py-3 px-10 mt-7 text-center text-white rounded-xl"
            type="submit"
          >
            Sign Up
          </button> */}
          <input
            className="sign-in py-3 px-10 mt-7 text-center text-white rounded-xl"
            type="submit"
            value="Sign Up"
          />
        </div>
        <div className="flex flex-row justify-center mt-4">
          {loading ? (
            <>
              <Oval color="#2f807c" height={50} width={50} />
            </>
          ) : (
            ""
          )}
        </div>
      </form>
      <div className="text-center text-base font-light">
        <p>Already have an account?</p>
        <a
          onClick={() => {
            history.push("/");
          }}
          href=""
          className="text-blue-500 underline"
        >
          Sign in
        </a>
      </div>
    </div>
  );
};

export default Registration;
