// import IndustriesTable from "../../../components/IndustriesTable";

import IndustryTemp from "../../../components/IndustryTemp";
import IndustryTable from "./IndustryTable";

const Industries = () => {
  return <IndustryTable />;
};

export default Industries;
