import React from "react";

const DeleteTableBtn = (props) => {
  return (
    <div className=" deleteGradient text-white py-3 px-4 text-base">
      {props.text}
    </div>
  );
};

export default DeleteTableBtn;
