import React from "react";
import SearchBar from "../../../components/SearchBar";
import Faq from "react-faq-component";
const Faqs = () => {
  const data = {
    rows: [
      {
        title: "How does this portal works?",
        content:
          "Feedmeback’s dedicated AI utlizes Natural Language Processing (NLP) and reads through your customer reviews and opinions, translating them into opportunities to benefit your business. Feedmeback’s dedicated AI utlizes Natural Language Processing (NLP) and reads through your customer reviews and opinions, translating them into opportunities to benefit your business. Feedmeback’s dedicated AI utlizes Natural Language Processing (NLP) and reads through your customer reviews and opinions, translating them into opportunities to benefit your business. Feedmeback’s dedicated AI utlizes Natural Language Processing (NLP) and reads through your customer reviews and opinions, translating them into opportunities to benefit your business. ",
      },
      {
        title: "How does this Feedback works?",
        content:
          "Nunc maximus, magna at ultricies elementum, risus turpis vulputate quam.",
      },
      {
        title: "How does this Feedback works?",
        content:
          "Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc",
      },
      {
        title: "What is the package version",
        content: "v1.0.5",
      },
    ],
    styles: {
      titleFontweight: "bold",
    },
  };
  return (
    <>
      <div className="flex flex-row">
        <SearchBar />
      </div>
      <div className="mt-5">
        <Faq data={data} />
      </div>
    </>
  );
};

export default Faqs;
