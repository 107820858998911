import React from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import emailsent from "../images/login/emailSent.svg";
const EmailSet = () => {
  const history = useHistory();
  return (
    <>
      <div className="flex flex-col gap-3 mt-28  justify-content-center items-center">
        <img className="h-32 w-32" src={emailsent} alt="" />
        <div className="text-center">
          <h1 className="text-4xl text-black font-bold mt-4 text-center">
            Email Sent
          </h1>
          <h1 className="text-xl text-black font-normal mt-4 text-center">
            Instructions to Reset your Password
            <br /> Has Been sent to your Email Address
          </h1>
        </div>
        <form className=" md:w-1/4" action="">
          <div className="flex flex-col justify-center text-center items-center">
            <Link
              to="/"
              //   onClick={() => {
              //     history.push("/su/dashboard");
              //   }}
              className="sign-in py-3 px-10 mt-7 text-center text-white rounded-xl"
            >
              Back
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default EmailSet;
