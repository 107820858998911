import FaqComp from "../../../components/FaqComp";

const Faqs = () => {
  return (
    <div className="flex flex-col gap-3">
      <FaqComp />
      <FaqComp />
      <FaqComp />
      <FaqComp />
      <FaqComp />
      <FaqComp />
      <FaqComp />
      <FaqComp />
    </div>
  );
};

export default Faqs;
