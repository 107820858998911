import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import forgotPw from "../images/login/forgotPw.png";
import { Oval } from "react-loader-spinner";
import toast from "react-hot-toast";
import axios from "axios";
const ForgotPw = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const handleChange = (e) => {
    console.log("email", e.target.value);
    setEmail(e.target.value);
  };
  const [loading, setLoading] = useState(false);

  const notifyError = (text) => {
    toast.error(text);
  };

  const notifySuccess = (text) => {
    toast.success(text);
  };

  const submitDetail = async (e) => {
    e.preventDefault();
    if (email == "") {
      toast.error("Email Address is required");
    } else {
      const url = `${process.env.REACT_APP_BASE_URL}/forgot-password`;
      const data = {
        email: email,
      };
      setLoading(true);
      await axios
        .post(url, data)

        .then((res) => {
          console.log(res);
          setLoading(false);
          notifySuccess(res.data.message);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          notifyError(error.message);
          // notifyError();
        });
    }
  };
  return (
    <>
      <div className="flex flex-col gap-3 mt-28 justify-content-center items-center">
        <img className=" h-32 w-32" src={forgotPw} alt="" />
        <div className="text-center">
          <h1 className="text-3xl text-black font-medium mt-4 text-center">
            Forgot Password
          </h1>
          <h1 className="text-xl text-black font-light mt-4 text-center">
            Enter your Email address for
            <br />
            Instructions to be sent
          </h1>
        </div>
        <form className=" md:w-1/4" action="">
          <div className="flex flex-col">
            <input
              type="email"
              placeholder="Anonymous123@domain.com"
              name="email"
              className="px-5 py-3 rounded-xl input-field bg-white rounded-0 col-md-3 mt-3"
              onChange={handleChange}
            />
          </div>

          <div className="flex flex-col justify-center text-center items-center">
            {/* <Link
              to="/email-sent"
              onClick={() => {
                history.push("/email-sent");
              }}
              className="sign-in py-3 px-10 mt-7 text-center text-white rounded-xl"
            > */}
            <button
              className="sign-in py-3 px-10 mt-7 text-center text-white rounded-xl"
              onClick={submitDetail}
            >
              Submit
            </button>
            {/* </Link> */}
          </div>
          <div className="flex flex-row justify-center mt-4">
            {loading ? (
              <>
                <Oval color="#2f807c" height={50} width={50} />
              </>
            ) : (
              ""
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default ForgotPw;
