const SearchBar = () => {
  return (
    <div className="relative">
      {/* <div className="absolute top-4 left-3">
        {" "}
        <i className="fa fa-search text-gray-400 z-20 hover:text-gray-500"></i>{" "}
      </div>{" "} */}

      <div className="flex flex-row">
        <div>
          <input
            type="text"
            className="h-12 w-full pl-4 pr-24  z-0 focus:shadow focus:outline-none border-2 border-light-grey rounded "
            placeholder="Search User"
          />
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12 ml-3 border-2 border-light-grey rounded p-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>
      </div>

      {/* <div className="absolute top-2 right-2 border-solid border-2 border-light-grey">
        {" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </div> */}
    </div>
  );
};

export default SearchBar;
