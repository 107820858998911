import React, { useEffect, useState } from "react";
import logo from "../images/sidebar/fmbLogo.svg";
import dashboard from "../images/sidebar/dashboard.svg";
import FeedBackForm from "../images/sidebar/FeedBackForm.svg";
import hellp from "../images/sidebar/hellp.svg";
import vectorRequest from "../images/sidebar/vectorRequest.svg";
import logout from "../images/sidebar/logout.svg";
import faqs from "../images/sidebar/faqs.png";
import payment from "../images/sidebar/payment.svg";
import vector from "../images/sidebar/vector.svg";
import addons from "../images/sidebar/addOns.svg";

import { NavLink } from "react-router-dom";
// import Dashboard from "../pages/Dashboard/Dashboard";
import { useLocation } from "react-router-dom";
const SideBar = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [helpDrop, setHelpDrop] = useState(false);
  const [feedDrop, setFeedDrop] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const helpFunc = () => {
    setHelpDrop(!helpDrop);
    // setFeedDrop(false);
  };

  const display = () => {
    //console.log("Pressed");
    setShowSidebar(!showSidebar);
    setMobileView(true);
  };

  const test = () => {
    console.log("clicked");
  };

  //getting url params and setting
  const location = useLocation();
  // console.log(location.pathname);
  if (location.pathname == "/help/faqs") {
    console.log("Matched !!");
  } else if (location.pathname !== "help/faqs") {
    // setHelpDrop(false);
  }
  useEffect(() => {
    if (
      location.pathname == "/help/faqs" ||
      location.pathname == "/help/requestTable" ||
      location.pathname == "/help/suggestionTable" ||
      location.pathname == "/help/complain"
    ) {
      console.log("Matched !!");
      setHelpDrop(true);
    }
  }, []);

  return (
    <>
      {/* mobileView */}
      <div className="flex flex-row-reverse md:hidden">
        {/* logo */}

        {/* mobile menu button */}
        <button
          className="mobile-menu-button justify-start p-4 focus:outline-none focus:bg-gray-100"
          onClick={display}
        >
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>

      {/* MainSideBar */}
      <div
        className={
          showSidebar
            ? "z-50 overflow-y-auto scrollbar sidebar bg-sidebar sideBar_position -translate-x-full text-white w-60 space-y-6 py-7 absolute inset-y-0 left-0 transform  md:relative md:translate-x-0 transition duration-200 ease-in-out"
            : "z-50 overflow-y-auto scrollbar sidebar bg-sidebar text-white w-60 space-y-6 py-7   absolute inset-y-0 left-0 transform  md:relative md:translate-x-0 transition duration-200 ease-in-out "
        }
      >
        <div className="flex justify-center">
          <img src={logo} className="mb-3" alt="FMB Logo" />
        </div>
        <ul className="space-y-2">
          <li className="hover:bg-sideHover">
            <NavLink
              to="/dashboard"
              activeClassName="active"
              className="flex py-2.5  items-center   transition duration-200   text-sm"
              // onClick={() => {
              //   setHelpDrop(false);
              // }}
            >
              <div className="ml-3 flex items-center">
                <img src={dashboard} alt="sidebar icons" />
                <span className="flex-1 ml-3 text-left text-lg whitespace-nowrap">
                  Dashboard
                </span>
              </div>
            </NavLink>
          </li>
          <li className="hover:bg-sideHover">
            <NavLink
              to="/fbtaker/datatable"
              activeClassName="active"
              className="flex py-2.5  items-center   transition duration-200   text-sm"
            >
              <div className="ml-3 flex items-center">
                <img src={FeedBackForm} alt="sidebar icons" />
                <span className="flex-1 ml-3 text-left text-lg whitespace-nowrap">
                  Feedback Takers
                </span>
              </div>
            </NavLink>
          </li>
          <li className="hover:bg-sideHover">
            <NavLink
              to="/fbList/datatable"
              activeClassName="active"
              className="flex py-2.5  items-center   transition duration-200   text-sm"
            >
              <div className="ml-3 flex items-center">
                <img src={FeedBackForm} alt="sidebar icons" />
                <span className="flex-1 ml-3 text-left text-lg whitespace-nowrap">
                  Feedback List
                </span>
              </div>
            </NavLink>
          </li>
          <li className="hover:bg-sideHover">
            <NavLink
              activeClassName="active"
              className="flex py-2.5  items-center   transition duration-200   text-sm"
              to="/fbSystem/table"
            >
              <div className="ml-3 flex items-center">
                <img src={FeedBackForm} alt="sidebar icons" />
                <span className="flex-1 ml-3 text-left text-lg whitespace-nowrap">
                  Feedback System
                </span>
              </div>
            </NavLink>
          </li>
          <li className="hover:bg-sideHover">
            <NavLink
              to="/welcomeScreen"
              activeClassName="active"
              className="flex py-2.5  items-center   transition duration-200   text-sm"
            >
              <div className="ml-3 flex items-center">
                <img src={logout} alt="sidebar icons" />
                <span className="flex-1 ml-3 text-left text-lg whitespace-nowrap">
                  Welcome Screen
                </span>
              </div>
            </NavLink>
          </li>
          <li className="hover:bg-sideHover">
            <NavLink
              to="/addOns"
              activeClassName="active"
              className="flex py-2.5  items-center   transition duration-200   text-sm"
            >
              <div className="ml-3 flex items-center">
                <img src={addons} alt="sidebar icons" />
                <span className="flex-1 ml-3 text-left text-lg whitespace-nowrap">
                  Add ons
                </span>
              </div>
            </NavLink>
          </li>
          {/* <li className="hover:bg-sideHover">
            <NavLink
              to="/"
              activeClassName="active"
              className="flex py-2.5 px-4 items-center   transition duration-200   text-sm"
            >
              <img src={vectorRequest} alt="sidebar icons" />
              <span className="flex-1 ml-3 text-left text-lg whitespace-normal ">
                Vector Addition Request
              </span>
            </NavLink>
          </li> */}
          <li className="hover:bg-sideHover">
            {/* <NavLink
              to="/wrapper"
              activeClassName="active"
              className="flex py-2.5 px-4 items-center  rounded transition duration-200   text-sm"
            >
              <img src={hellp} alt="sidebar icons" />
              <span className="flex-1 ml-3 text-left text-lg whitespace-nowrap">
                Help
              </span>
            </NavLink> */}
            <div
              className={
                helpDrop
                  ? "py-2.5 bg-dropDown transition duration-200 text-sm"
                  : "py-2.5 transition duration-200   text-sm"
              }
            >
              <div className="flex items-center ml-3">
                <img src={hellp} alt="sidebar icons" />
                <button
                  className="flex-1 block ml-3 text-left text-lg whitespace-nowrap"
                  onClick={helpFunc}
                >
                  Help
                </button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-3"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  onClick={helpFunc}
                  cursor="pointer"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              {helpDrop ? (
                <>
                  <ul id="dropdown-example" className=" py-2 space-y-2  ">
                    <li className="hover:bg-dropDown">
                      <NavLink
                        to="/help/faqs"
                        activeClassName="active"
                        className="flex py-2.5  items-center  transition duration-200   text-sm"
                      >
                        <div className="ml-7 flex items-center">
                          <span className="ml-5 break-all text-lg">FAQs</span>
                        </div>
                      </NavLink>
                    </li>
                    <li className="hover:bg-dropDown">
                      <NavLink
                        to="/help/requestTable"
                        activeClassName="active"
                        className="flex py-2.5 items-center  transition duration-200   text-sm"
                      >
                        <div className="ml-7 flex items-center">
                          <span className="ml-5 break-all text-lg">
                            Request Help
                          </span>
                        </div>
                      </NavLink>
                    </li>
                    <li className="hover:bg-dropDown">
                      <NavLink
                        to="/help/suggestionTable"
                        activeClassName="active"
                        className="flex py-2.5 items-center  transition duration-200   text-sm"
                      >
                        <div className="ml-7 flex items-center">
                          <span className="ml-5 break-all text-lg">
                            Suggestions
                          </span>
                        </div>
                      </NavLink>
                    </li>
                    <li className="hover:bg-dropDown">
                      <NavLink
                        to="/help/complain"
                        activeClassName="active"
                        className="flex py-2.5 items-center  transition duration-200   text-sm"
                      >
                        <div className="ml-7 flex items-center">
                          <span className="ml-5 break-all text-lg">
                            Complains
                          </span>
                        </div>
                      </NavLink>
                    </li>
                  </ul>
                </>
              ) : null}
            </div>
          </li>
          <li className="hover:bg-sideHover">
            <NavLink
              to="/payment/table"
              activeClassName="active"
              className="flex py-2.5  items-center   transition duration-200   text-sm"
            >
              <div className="ml-3 flex items-center">
                <img src={payment} alt="sidebar icons" />
                <span className="flex-1 ml-3 text-left text-lg whitespace-nowrap">
                  Payment History
                </span>
              </div>
            </NavLink>
          </li>
          <li className="hover:bg-sideHover">
            <NavLink
              to="/vector/table"
              activeClassName="active"
              className="flex py-2.5  items-center   transition duration-200   text-sm"
            >
              <div className="ml-3 flex items-center">
                <img src={vector} alt="sidebar icons" />
                <span className="flex-1 ml-3 text-left text-lg whitespace-nowrap">
                  Vector Request
                </span>
              </div>
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SideBar;
