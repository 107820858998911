import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import MaterialTable from "material-table";
import tableIcons from "../../../components/MaterialTableIcons";
import { Paper } from "@material-ui/core";
import FindInPageOutlinedIcon from "@material-ui/icons/FindInPageOutlined";
import axios from "axios";
import demoHotel from "../../../images/demoHotel.png";
import DeleteIcon from "@material-ui/icons/Delete";
import { AddBrandSchema } from "../../../FormSchemas/AddBrandSchema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import Modal from "../../../components/Modal";

const ActiveBrandsTable = () => {
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [industryName, setIndustryName] = useState("");
  const [industryID, setIndustryID] = useState("");

  const closeEditModal = () => {
    setTimeout(() => {
      setEditModal(false);
    }, 10);
  };

  const closeModal = () => {
    setTimeout(() => {
      setModal(false);
    }, 10);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AddBrandSchema),
  });

  const yourRef = React.createRef();

  const history = useHistory();

  const submitBrandForm = async (data) => {
    console.log("data:", data);

    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    const url = `${process.env.REACT_APP_BASE_URL}/createBrand`;
    await axios
      .post(url, data, {
        headers: headers,
      })
      .then((res) => {
        console.log(res);
        console.log("Success !!");
        // setFbsystemID(res.data.CompanyFeedBackSystemId);
        // reset();
        //  notify();
      })
      .catch((error) => {
        console.log(error);
        // notifyError();
      });
  };

  //edit brand form
  const editBrandForm = async (data, e) => {
    console.log("data:", data);

    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    const url = `${process.env.REACT_APP_BASE_URL}/updateBrand/${industryID}`;
    await axios
      .put(url, data, {
        headers: headers,
      })
      .then((res) => {
        console.log(res);
        console.log("Success !!");
        // setFbsystemID(res.data.CompanyFeedBackSystemId);
        // reset();
        //  notify();
      })
      .catch((error) => {
        console.log(error);
        // notifyError();
      });
  };

  //     // *apicall for FeedbackID
  //     const url2 = `${process.env.REACT_APP_BASE_URL}/createFeedBackId`;
  //     await axios
  //       .post(url2, formval, {
  //         headers: headers,
  //       })
  //       .then((res) => {
  //         console.log(res);
  //         console.log("Success !!");
  //         setFbID(res.data.FeedBackId);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });

  //     // *apicall for createCompanyFeedBackSystemPivot
  //     if (fbSystemID && fbID) {
  //       console.log("fbsyste and systemID hai");
  //       const url3 = `${process.env.REACT_APP_BASE_URL}/createCompanyFeedBackSystemPivot`;
  //       await axios;
  //       const val = {
  //         company_id: `${localStorage.getItem("company_id")}`,
  //         company_feed_back_system_id: fbSystemID,
  //         company_feedbacks_id: fbID,
  //       };
  //       console.log("VAL PVOT", val);

  //       await axios
  //         .post(url3, val, {
  //           headers: headers,
  //         })
  //         .then((res) => {
  //           console.log(res);
  //           console.log("api3 !!");
  //           history.push("/fbSystem/table/otherDetail");
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     }
  //   };

  const columns = [
    { title: "BRAND NAME", field: "name" },
    { title: "CREATED DATE", field: "created_at" },
    { title: "BRAND ID", field: "brand_id" },
    { title: "PAYMENT STATUS", field: "status" },
  ];

  return (
    <>
      <div className="flex flex-wrap gap-6 flex-row w-100 mt-3 justify-end items-center mb-4">
        <div>
          <button
            className="gradientColor  text-white w-40 py-3 px-4 "
            onClick={() => setModal(true)}
          >
            Add Brand
          </button>
        </div>
      </div>

      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        title={null}
        icons={tableIcons}
        columns={columns}
        options={{
          headerStyle: {
            fontWeight: "bold",
            fontSize: "15px",
            textTransform: "uppercase",
          },
          search: true,
          actionsColumnIndex: -1,
        }}
        actions={[
          {
            icon: () => <DeleteIcon />,
            tooltip: "Delete",
            onClick: async (event, rowData) => {
              console.log("ques id", rowData.questionnaire_id);
              // deleteRow(rowData.questionnaire_id, scopedRef);
              const scopedRef = yourRef.current;
              const headers = {
                Authorization: `Bearer +${localStorage.getItem("token")}`,
              };
              var config = {
                method: "delete",
                url: `${process.env.REACT_APP_BASE_URL}/deleteQuestionnaire/${rowData.questionnaire_id}`,
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              };
            },
          },
          {
            icon: () => <EditIcon />,
            tooltip: "Edit",
            onClick: (event, rowData) => {
              setEditModal(true);
              setIndustryName(rowData.name);
              setIndustryID(rowData.id);
              console.log("val", rowData.id);
            },
          },
          // rowData => ({
          //   icon: 'delete',
          //   tooltip: 'Delete User',
          //   onClick: (event, rowData) => confirm("You want to delete " + rowData.name),
          //   disabled: rowData.birthYear < 2000
          // })
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append(
              "Authorization",
              `Bearer ${localStorage.getItem("token")}`
            );

            var requestOptions = {
              method: "GET",
              headers: myHeaders,
              redirect: "follow",
            };
            let url = `${process.env.REACT_APP_BASE_URL}/getActiveBrandList?`;
            url += `page=${query.page + 1}`;
            // url += "page=" + query.page + 1;
            // url += "&page=" + (query.page + 1);
            // console.log("page", parseInt(query.page));
            fetch(url, requestOptions)
              .then((response) => response.json())
              .then((result) => {
                console.log("apicall", result);
                // setData(result.data.data);
                console.log(result.data);
                console.log("Querry", query);
                // console.log("current pg", result.data.current_page);

                resolve({
                  data: result,
                  page: query.page,
                  totalCount: result.total,
                });
              })
              .catch((error) => console.log("error", error));
          })
        }
      />
      {/* modal */}
      <Modal show={modal} close={closeModal}>
        <form
          className="flex flex-col gap-3"
          onSubmit={handleSubmit(submitBrandForm)}
        >
          <img className="self-center h-28 w-28" src={demoHotel} alt="" />

          <div className="gap-3 grid grid-cols-2">
            <div>
              <label className="font-bold text-lg">Brand Name</label>
              <input
                type="text"
                className="h-12 w-full pl-4 pr-4  z-0 focus:shadow focus:outline-none border text-sm border-light-grey rounded "
                name="name"
                {...register("name")}
              />
            </div>

            <div>
              <label className="font-bold text-lg">BrandID</label>
              <input
                type="text"
                name="brand_id"
                className="h-12 w-full pl-4 pr-4  z-0 focus:shadow focus:outline-none border text-sm border-light-grey rounded "
                {...register("brand_id")}
              />
            </div>

            <div>
              <label className="font-bold text-lg">Type ID</label>
              <input
                type="number"
                name="type_id"
                className="h-12 w-full pl-4 pr-4  z-0 focus:shadow focus:outline-none border text-sm border-light-grey rounded "
                {...register("type_id")}
              />
            </div>

            <div>
              <label className="font-bold text-lg">Phone Number</label>
              <input
                type="number"
                name="contact_no"
                className="h-12 w-full pl-4 pr-4  z-0 focus:shadow focus:outline-none border text-sm border-light-grey rounded "
                {...register("contact_no")}
              />
            </div>

            <div>
              <label className="font-bold text-lg">Email</label>
              <input
                type="email"
                name="email"
                className="h-12 w-full pl-4 pr-4  z-0 focus:shadow focus:outline-none border text-sm border-light-grey rounded "
                {...register("email")}
              />
            </div>

            {/* <div>
              <label className="font-bold text-lg">Password</label>
              <input
                type="text"
                className="h-12 w-full pl-4 pr-4  z-0 focus:shadow focus:outline-none border text-sm border-light-grey rounded "
              
              />
            </div> */}
            {/* <div>
              <label className="font-bold text-lg">Address</label>
              <textarea
                className="h-12 w-full p-4 z-0 focus:shadow focus:outline-none border text-sm border-light-grey rounded "
                {...register("address")}
              />
            </div> */}
          </div>

          <div className="grid grid-cols-2 self-center  w-2/4 justify-between gap-3">
            <button
              onClick={closeModal}
              className="redbutton text-white rounded-lg grow py-2 px-4 "
            >
              Cancel
            </button>
            <input
              type="submit"
              value="Create Brand"
              className="greybutton text-white rounded-lg grow py-2 px-4 "
            ></input>
          </div>
        </form>
      </Modal>

      <Modal show={editModal} close={closeEditModal}>
        <div>
          <form
            className="flex flex-col gap-3"
            onSubmit={handleSubmit(editBrandForm)}
            action=""
          >
            <h1 className="text-lg font-bold">Edit Brand Name</h1>

            <input
              type="text"
              name="name"
              className="h-12 w-full pl-4 pr-4  z-0 focus:shadow focus:outline-none border text-sm border-light-grey rounded "
              placeholder={industryName}
              {...register("name")}
            />
            <div className="grid grid-cols-2  justify-between gap-3">
              <button
                onClick={closeEditModal}
                className="redbutton text-white rounded-lg grow py-2 px-4 "
              >
                Cancel
              </button>
              <input
                type="submit"
                value="Save"
                className="greybutton text-white rounded-lg grow py-2 px-4 "
              ></input>
            </div>
          </form>
        </div>
      </Modal>
      {/* modal */}
    </>
  );
};

export default ActiveBrandsTable;
