import React from "react";

const EditTableBtn = (props) => {
  return (
    <div className="editGradient text-white py-3 px-4 text-base">
      {props.text}
    </div>
  );
};

export default EditTableBtn;
