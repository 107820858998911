import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import MaterialTable from "material-table";
import tableIcons from "../../../components/MaterialTableIcons";
import { Paper } from "@material-ui/core";
import FindInPageOutlinedIcon from "@material-ui/icons/FindInPageOutlined";
import axios from "axios";
import demoHotel from "../../../images/demoHotel.png";
import DeleteIcon from "@material-ui/icons/Delete";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import Modal from "../../../components/Modal";

const PaymentRequestsTable = () => {
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [industryName, setIndustryName] = useState("");

  const closeEditModal = () => {
    setTimeout(() => {
      setEditModal(false);
    }, 10);
  };

  const closeModal = () => {
    setTimeout(() => {
      setModal(false);
    }, 10);
  };

  const yourRef = React.createRef();

  const history = useHistory();
  //   const {
  //     register,
  //     handleSubmit,
  //     reset,
  //     setValue,
  //     formState: { errors },
  //   } = useForm({
  //     resolver: yupResolver(FeedbabckSystem),
  //   });

  //   const submitForm = async (data) => {
  //     console.log("data:", data);
  //     let data2 = {
  //       company_id: localStorage.getItem("company_id"),
  //     };
  //     const formval = { ...data, ...data2 };
  //     const headers = {
  //       Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     };
  //     // *apicall for createCompanyFeedBackSystem
  //     const url = `${process.env.REACT_APP_BASE_URL}/createCompanyFeedBackSystem`;
  //     await axios
  //       .post(url, formval, {
  //         headers: headers,
  //       })
  //       .then((res) => {
  //         console.log(res);
  //         console.log("Success !!");
  //         setFbsystemID(res.data.CompanyFeedBackSystemId);
  //         // reset();
  //         //  notify();
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         // notifyError();
  //       });

  //     // *apicall for FeedbackID
  //     const url2 = `${process.env.REACT_APP_BASE_URL}/createFeedBackId`;
  //     await axios
  //       .post(url2, formval, {
  //         headers: headers,
  //       })
  //       .then((res) => {
  //         console.log(res);
  //         console.log("Success !!");
  //         setFbID(res.data.FeedBackId);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });

  //     // *apicall for createCompanyFeedBackSystemPivot
  //     if (fbSystemID && fbID) {
  //       console.log("fbsyste and systemID hai");
  //       const url3 = `${process.env.REACT_APP_BASE_URL}/createCompanyFeedBackSystemPivot`;
  //       await axios;
  //       const val = {
  //         company_id: `${localStorage.getItem("company_id")}`,
  //         company_feed_back_system_id: fbSystemID,
  //         company_feedbacks_id: fbID,
  //       };
  //       console.log("VAL PVOT", val);

  //       await axios
  //         .post(url3, val, {
  //           headers: headers,
  //         })
  //         .then((res) => {
  //           console.log(res);
  //           console.log("api3 !!");
  //           history.push("/fbSystem/table/otherDetail");
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     }
  //   };

  const columns = [
    { title: "PAYMENT TITLE", field: "name" },
    { title: "PAYMENT DATE", field: "payment_date" },
    { title: "PAYMENT DUE DATE", field: "due_date" },
    { title: "PAYMENT FROM", field: "" },
  ];

  return (
    <>
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        title={null}
        icons={tableIcons}
        columns={columns}
        options={{
          headerStyle: {
            fontWeight: "bold",
            fontSize: "15px",
            textTransform: "uppercase",
          },
          search: true,
          actionsColumnIndex: -1,
        }}
        actions={[
          {
            icon: () => (
              <a className="bg-gray-400 text-sm text-white  text-center p-2 rounded-md cursor-pointer  flex flex-col justify-center align-middle">
                CONFIRM
              </a>
            ),
            tooltip: "Confirm",
            onClick: async (event, rowData) => {
              console.log("ques id", rowData.questionnaire_id);
              // deleteRow(rowData.questionnaire_id, scopedRef);
              const scopedRef = yourRef.current;
              const headers = {
                Authorization: `Bearer +${localStorage.getItem("token")}`,
              };
              var config = {
                method: "delete",
                url: `${process.env.REACT_APP_BASE_URL}/deleteQuestionnaire/${rowData.questionnaire_id}`,
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              };
            },
          },
          {
            icon: () => (
              <a className="greenAction-btn text-sm text-white  text-center  p-2 rounded-md cursor-pointer  flex flex-col justify-center align-middle">
                VIEW
              </a>
            ),
            tooltip: "View",
            onClick: (event, rowData) => {
              setEditModal(true);
              setIndustryName(rowData.name);
              console.log("val", rowData.id);
            },
          },
          // rowData => ({
          //   icon: 'delete',
          //   tooltip: 'Delete User',
          //   onClick: (event, rowData) => confirm("You want to delete " + rowData.name),
          //   disabled: rowData.birthYear < 2000
          // })
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append(
              "Authorization",
              `Bearer ${localStorage.getItem("token")}`
            );

            var requestOptions = {
              method: "GET",
              headers: myHeaders,
              redirect: "follow",
            };
            let url = `${process.env.REACT_APP_BASE_URL}/allCompanyPayments/2?`;
            url += `page=${query.page + 1}`;

            fetch(url, requestOptions)
              .then((response) => response.json())
              .then((result) => {
                console.log("apicall", result);

                console.log("this is data" + result.data);
                console.log("Querry", query);

                resolve({
                  data: result.data.data,
                  page: result.data.current_page,
                });
              })
              .catch((error) => console.log("error", error));
          })
        }
      />
      {/* modal */}

      <Modal show={editModal} close={closeEditModal}>
        <div className="flex w-96 flex-col gap-3">
          <h1 className="text-2xl font-bold">Unlimited Feedbacks</h1>

          <div className="flex flex-col md:flex-row gap-2">
            <h2 className="font-bold">Payment Date:</h2>
            <h2>19/1/2022</h2>
          </div>

          <div className="flex flex-col md:flex-row gap-3">
            <h2 className="font-bold">Payment Due Date:</h2>
            <h2>19/1/2022</h2>
          </div>

          <div className="flex flex-col md:flex-row gap-3">
            <h2 className="font-bold">Payment From:</h2>
            <h2>John Doe</h2>
          </div>

          <div className="grid grid-cols-2  justify-between gap-3">
            <button
              onClick={closeEditModal}
              className="attachmentbutton self-end text-white rounded-lg grow py-2 px-4 "
            >
              View Attachment
            </button>
          </div>
        </div>
      </Modal>
      {/* modal */}
    </>
  );
};

export default PaymentRequestsTable;
