import React, { useState } from "react";
import SearchBar from "../../../components/SearchBar";
import Table from "../../../components/Table";
import TableFooter from "../../../components/TableFooter";
import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import tableIcons from "../../../components/MaterialTableIcons";
import DeleteTableBtn from "../../../components/DeleteTableBtn";
import EditTableBtn from "../../../components/EditTableBtn";
const Complain = () => {
  const [modal, setModal] = useState(false);
  const showModal = () => {
    console.log("MODAL Clicked");
    setModal(true);
  };
  const columns = [
    { title: "S.No", field: "name" },
    { title: "COMPLAIN TITLE", field: "company_feedbacks_id" },
    { title: "COMPLAIN ID", field: "company_feedbacks_id" },
    { title: "SUBMITTED ON", field: "company_feedbacks_id" },
    { title: "COMPLAIN STATUS	ACTIONS", field: "company_feedbacks_id" },
    // { title: "ACTIONS", filed: "action" },
  ];

  const action1 = "Cancel";
  const action2 = "View";

  return (
    <>
      <div
        className="flex flex-wrap gap-6 flex-row w-100 mt-3 justify-end items-center"
        id="root"
      >
        <div>
          <button
            className="gradientColor  text-white  py-3 px-4 "
            onClick={showModal}
          >
            Add Request
          </button>
        </div>
      </div>
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        title=""
        icons={tableIcons}
        columns={columns}
        options={{
          headerStyle: {
            fontWeight: "bold",
            fontSize: "15px",
            textTransform: "uppercase",
          },
          // searchFieldAlignment: "left",
          search: true,
          actionsColumnIndex: -1,
        }}
        actions={[
          {
            icon: () => <DeleteTableBtn text={action1} />,
            // tooltip: { action1 },
            onClick: (event, rowData) => {
              console.log("val", rowData.id);
            },
          },
          {
            icon: () => <EditTableBtn text={action2} />,
            // tooltip: { action2 },
            onClick: (event, rowData) => {
              console.log("val", rowData.company_feedbacks_id);
            },
          },
          // rowData => ({
          //   icon: 'delete',
          //   tooltip: 'Delete User',
          //   onClick: (event, rowData) => confirm("You want to delete " + rowData.name),
          //   disabled: rowData.birthYear < 2000
          // })
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append(
              "Authorization",
              `Bearer ${localStorage.getItem("token")}`
            );

            var requestOptions = {
              method: "GET",
              headers: myHeaders,
              redirect: "follow",
            };
            let url = `${
              process.env.REACT_APP_BASE_URL
            }/getCompanyFeedbackSystemPivot/${localStorage.getItem(
              "company_id"
            )}?`;
            url += `page=${query.page + 1}`;
            // url += "page=" + query.page + 1;
            // url += "&page=" + (query.page + 1);
            // console.log("page", parseInt(query.page));
            fetch(url, requestOptions)
              .then((response) => response.json())
              .then((result) => {
                console.log("apicall", result);
                // setData(result.data.data);
                console.log(result.data);
                // console.log("Querry", query);
                // console.log("current pg", result.data.current_page);

                resolve({
                  data: result.data.data,
                  page: query.page,
                  totalCount: result.data.total,
                });
              })
              .catch((error) => console.log("error", error));
          })
        }
      />

      {/* modal */}
      {modal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative p-4   md:w-2/5  mt-auto md:h-auto md:mt-0">
              {/* h-full class removed to modal responsive on mobile screen abvoe div */}
              {/*content*/}
              <div className="rounded-lg  p-4 shadow-lg relative flex flex-col md:w-100 bg-white outline-none focus:outline-none">
                {/*header*/}

                <div className="flex items-start justify-between  border-solid  ">
                  <h3 className="text-4xl text-black font-bold">
                    Bugs in Dashboard
                  </h3>
                  <button
                    className="p-1 ml-auto  border-0 text-white bg-modalCross rounded-full float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setModal(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                {/*body*/}
                <div className="p-2 w-3/4">
                  <div className="flex flex-col">
                    <p className="text-black text-xl mt-4">
                      You will recieve all the Notifications through SMS and
                      Email. You will recieve all the Notifications through SMS
                      and Email. You will recieve all the Notifications through
                      SMS and Email
                    </p>
                    <p className="text-2xl mt-3">
                      <span className="font-medium">Complain Status:</span>
                      <span className="text-green-400 font-normal">
                        {" "}
                        RESOLVED
                      </span>
                    </p>
                    <p className="text-2xl mt-3">
                      <span className="font-medium">Complain ID:</span>
                      <span className="text-black font-normal"> NDIA7234N</span>
                    </p>
                    <p className="text-2xl mt-3">
                      <span className="font-medium">Submitted on:</span>
                      <span className="text-black font-normal"> 19/2/2022</span>
                    </p>
                    <h3 className="font-medium text-4xl mt-3">Response</h3>
                    <h3 className="font-medium text-3xl mt-3">
                      We will fix that
                    </h3>
                    <p className="text-xl">
                      You will recieve all the Notifications through SMS and
                      Email. You will recieve all the Notifications through SMS
                      and Email. You will recieve all the Notifications through
                      SMS and Email
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {/* modal */}
    </>
  );
};

export default Complain;
