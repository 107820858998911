import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import MaterialTable from "material-table";
import tableIcons from "../../../components/MaterialTableIcons";
import { Paper } from "@material-ui/core";
import FindInPageOutlinedIcon from "@material-ui/icons/FindInPageOutlined";
import axios from "axios";
import DeleteIcon from "@material-ui/icons/Delete";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FeedbabckSystem } from "../../../FormSchemas/FeedbackSystem";
import { useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import DeleteTableBtn from "../../../components/DeleteTableBtn";
import EditTableBtn from "../../../components/EditTableBtn";
const ActiveTable = () => {
  const [modal, setModal] = useState(false);
  const [selectType, setSelectType] = useState("");
  const [fbSystemID, setFbsystemID] = useState("");
  const [fbID, setFbID] = useState("");
  const [dropVal, setDropVal] = useState();
  const [row, setRow] = useState(false);
  const [rows, setRows] = useState([]);
  const [industry, setIndustry] = useState([]);

  const history = useHistory();
  const action1 = "Delete";
  const action2 = "View";
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(FeedbabckSystem),
  });

  const showModal = () => {
    setModal(true);
  };
  const selectFunc = (e) => {
    console.log("select funv");
    console.log("select ==", e.target.value);
    setSelectType(e.target.value);
  };
  const handleChange = (e) => {
    // console.log("val input", e.target.name);
    setDropVal(e.target.value);
  };

  useEffect(() => {
    showIndustry();
  }, []);

  const handleAdd = (e) => {
    e.preventDefault();
    const item = {
      option: dropVal,
    };

    setRows([...rows, item]);
    setRow(true);
  };

  const deleteRow = (idx) => (e) => {
    e.preventDefault();
    console.log("row index", idx);
  };

  const showIndustry = async (e) => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/showIndustry`;
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/showIndustry`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios(config)
      .then(function (res) {
        setIndustry(res.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // console.log("company_feed_back_system_id", fbSystemID);
  // console.log("company_feedbacks_id", fbID);
  const handleCategory = (e) => {
    console.log("select val", e.target.value);
  };
  const submitForm = async (data) => {
    console.log("data:", data);
    let data2 = {
      company_id: localStorage.getItem("company_id"),
    };
    const formval = { ...data, ...data2 };
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    // *apicall for createCompanyFeedBackSystem
    const url = `${process.env.REACT_APP_BASE_URL}/createCompanyFeedBackSystem`;
    await axios
      .post(url, formval, {
        headers: headers,
      })
      .then((res) => {
        console.log(res);
        console.log("Success !!");
        setFbsystemID(res.data.CompanyFeedBackSystemId);
        // reset();
        //  notify();
      })
      .catch((error) => {
        console.log(error);
        // notifyError();
      });

    // *apicall for FeedbackID
    const url2 = `${process.env.REACT_APP_BASE_URL}/createFeedBackId`;
    await axios
      .post(url2, formval, {
        headers: headers,
      })
      .then((res) => {
        console.log(res);
        console.log("Success !!");
        setFbID(res.data.FeedBackId);
      })
      .catch((error) => {
        console.log(error);
      });

    // *apicall for createCompanyFeedBackSystemPivot
    if (fbSystemID && fbID) {
      console.log("fbsyste and systemID hai");
      const url3 = `${process.env.REACT_APP_BASE_URL}/createCompanyFeedBackSystemPivot`;
      await axios;
      const val = {
        company_id: `${localStorage.getItem("company_id")}`,
        company_feed_back_system_id: fbSystemID,
        company_feedbacks_id: fbID,
      };
      console.log("VAL PVOT", val);

      await axios
        .post(url3, val, {
          headers: headers,
        })
        .then((res) => {
          console.log(res);
          console.log("api3 !!");
          history.push("/fbSystem/table/otherDetail");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const test1 = (e) => {
    console.log("test called", e.target.value);
  };

  // *!to delete specific dropdown in add question
  // handleRemoveSpecificRow = (idx) => () => {
  //   const rows = [...this.state.rows];
  //   rows.splice(idx, 1);
  //   this.setState({ rows });
  // };

  // console.log("FBsystemID", fbSystemID);

  const columns = [
    { title: "SYSTEM NAME", field: "name" },
    {
      title: "CREATED BY",
      field: "created at",
      render: (rowData) => rowData.created_at.split("T").slice(0, 1),
    },
    { title: "Questions", field: "company_feedbacks_id" },
    {
      title: "CREATED At",
      field: "created at",
      render: (rowData) => rowData.created_at.split("T").slice(0, 1),
    },
  ];
  return (
    <>
      <div className="flex flex-wrap gap-6 flex-row w-100 mt-3 justify-end items-center mb-4">
        <div>
          <button
            className="gradientColor  text-white w-40 py-3 px-4 "
            onClick={showModal}
          >
            Add System
          </button>
        </div>
      </div>

      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        title=""
        icons={tableIcons}
        columns={columns}
        options={{
          headerStyle: {
            fontWeight: "bold",
            fontSize: "15px",
            textTransform: "uppercase",
          },
          search: true,
          actionsColumnIndex: -1,
        }}
        actions={[
          {
            icon: () => <DeleteTableBtn text={action1} />,
            tooltip: "Delete",
            onClick: (event, rowData) => {
              console.log("val", rowData.id);
            },
          },
          {
            icon: () => <EditTableBtn text={action2} />,
            tooltip: "Edit",
            onClick: (event, rowData) => {
              console.log("val", rowData.company_feedbacks_id);
            },
          },
          // rowData => ({
          //   icon: 'delete',
          //   tooltip: 'Delete User',
          //   onClick: (event, rowData) => confirm("You want to delete " + rowData.name),
          //   disabled: rowData.birthYear < 2000
          // })
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append(
              "Authorization",
              `Bearer ${localStorage.getItem("token")}`
            );

            var requestOptions = {
              method: "GET",
              headers: myHeaders,
              redirect: "follow",
            };
            let url = `${
              process.env.REACT_APP_BASE_URL
            }/getCompanyFeedbackSystemPivot/${localStorage.getItem(
              "company_id"
            )}?`;
            url += `page=${query.page + 1}`;
            // url += "page=" + query.page + 1;
            // url += "&page=" + (query.page + 1);
            // console.log("page", parseInt(query.page));
            fetch(url, requestOptions)
              .then((response) => response.json())
              .then((result) => {
                console.log("apicall", result);
                // setData(result.data.data);
                console.log(result.data);
                console.log("Querry", query);
                console.log("current pg", result.data.current_page);

                resolve({
                  data: result.data.data,
                  page: query.page,
                  totalCount: result.data.total,
                });
              })
              .catch((error) => console.log("error", error));
          })
        }
      />
      {/* modal */}
      {modal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative p-4 w-full md:w-5/12 h-full md:h-auto">
              {/*content*/}
              <div className="rounded-lg  p-4 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}

                <div className="flex items-start justify-between  border-solid  ">
                  <button
                    className="p-1 ml-auto  border-0 text-white bg-modalCross rounded-full float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setModal(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                {/*body*/}
                <div className="p-2">
                  <form onSubmit={handleSubmit(submitForm)}>
                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full px-3 mb-6 md:mb-0">
                        <label
                          for="system Name"
                          className="block mb-2 text-2xl font-medium text-black  dark:text-gray-300"
                        >
                          System Name
                        </label>

                        <div className="flex flex-row gap-3">
                          <input
                            type="systemName"
                            name="name"
                            id="systemName"
                            className="bg-white border border-gray-300 text-black-900 text-lg 
                                focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 
                                dark:border-gray-500 dark:placeholder-gray-400
                              dark:text-white"
                            placeholder="Service Feedback System"
                            required
                            {...register("name")}
                          />
                          <p className="text-sm text-red-700 ml-3">
                            {" "}
                            {errors.name?.message}{" "}
                          </p>
                          <select
                            className="bg-white border border-gray-300 text-black-900 text-lg  
                          focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 
                          dark:border-gray-500 dark:placeholder-gray-400
                        dark:text-white"
                            id="grid-state"
                            placeholder=""
                            name="type_id"
                            onChange={handleCategory}
                          >
                            <option value="" disabled selected>
                              Select Category
                            </option>
                            {industry.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <p className="text-3xl font-semibold py-4 px-3 mb-6 md:mb-0">
                        UNIQUE FEEDBACK NUMBER
                      </p>
                      <div className="w-full md:w-1/2 px-3 mb-3 md:mb-0">
                        <label
                          className="block tracking-wide text-black text-lg font-medium"
                          for="grid-first-name"
                        >
                          Name
                        </label>
                        <input
                          className="appearance-none block w-full text-black border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                          id="grid-first-name"
                          type="text"
                          name="feedback_no"
                          placeholder="Table ID"
                          {...register("feedback_no")}
                        />
                        <p className="text-sm text-red-700 ml-3">
                          {" "}
                          {errors.feedback_no?.message}{" "}
                        </p>
                      </div>
                      <div className="w-full md:w-1/2 px-3 mb-5">
                        <label
                          className="block tracking-wide text-black text-lg font-medium"
                          for="grid-first-name"
                        >
                          Type
                        </label>
                        <div className="relative">
                          <select
                            className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-state"
                            placeholder="Numeric/AlphaNumeric"
                            onChange={test1}
                          >
                            <option value="" disabled selected>
                              Numeric/AlphaNumeric
                            </option>
                            <option value="helloWorld">Option 1</option>
                            <option>Option 2</option>
                            <option>Option 3</option>
                          </select>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg
                              className="fill-current h-4 w-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 px-3 mb-5">
                        <label
                          className="block tracking-wide text-black text-lg mb-2 font-medium"
                          for="grid-first-name"
                        >
                          Selection Type
                        </label>
                        <div className="relative">
                          <select
                            {...register("input_type")}
                            className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-state"
                            onChange={selectFunc}
                          >
                            <option value="" disabled selected>
                              Select Type
                            </option>
                            <option value="text">Text</option>
                            <option value="dropdown">Drop Down</option>
                          </select>
                          {/* <select {...register("gender")} onChange={selectFunc}>
                            <option value="female">female</option>
                            <option value="male">male</option>
                            <option value="other">other</option>
                          </select> */}
                          <p className="text-sm text-red-700 ml-3">
                            {" "}
                            {errors.input_type?.message}{" "}
                          </p>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg
                              className="fill-current h-4 w-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      {selectType === "text" ? (
                        <>
                          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label
                              className="block tracking-wide text-black text-lg mb-2 font-medium"
                              for="grid-first-name"
                            >
                              Number of IDs
                            </label>
                            <input
                              className="appearance-none block w-full text-black border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                              id="grid-first-name"
                              type="text"
                              placeholder="15"
                            />
                          </div>
                        </>
                      ) : null}
                      {selectType === "dropdown" ? (
                        <>
                          <div className="w-full  px-3 mb-6 md:mb-0">
                            <label
                              className="block tracking-wide text-black text-lg mb-2 font-medium"
                              for="grid-first-name"
                            >
                              Drop Down Options
                            </label>
                            <div className="flex flex-row gap-4">
                              <input
                                className="appearance-none block w-full text-black border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                id="grid-first-name"
                                type="text"
                                placeholder="Add Option"
                                onChange={handleChange}
                              />
                              <button
                                className="bg-gray-400 text-white whitespace-nowrap hover:bg-gray-600 py-2 px-4 rounded-lg mb-3"
                                onClick={handleAdd}
                              >
                                Add Option
                              </button>
                            </div>
                          </div>
                          <div className="w-full mt-4 px-3 ">
                            <table className="border-collapse border border-slate-400 w-full  ">
                              <thead className="text-left">
                                <tr>
                                  <th className="border border-slate-300">
                                    S.No
                                  </th>
                                  <th className="border border-slate-300">
                                    Option Name
                                  </th>
                                  <th className="border border-slate-300">
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {row && (
                                  <>
                                    {rows.map((item, idx) => (
                                      <tr key={idx}>
                                        <td className="border border-slate-300">
                                          {idx}
                                        </td>
                                        <td className="border border-slate-300">
                                          {item.option}
                                        </td>
                                        <td className="border border-slate-300">
                                          <button
                                            className="bg-deleteDrop text-white px-3 py-3 w-full"
                                            onClick={deleteRow(idx)}
                                          >
                                            Delete
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6"></div>

                    <div className="flex flex-wrap -mx-3 mb-2"></div>
                    <div className="flex flex-row justify-center">
                      <button
                        className=" bg-cancelBtn text-white rounded-lg md:w-40 py-3 px-4 "
                        onClick={() => setModal(false)}
                      >
                        Cancel
                      </button>

                      {/* "/fbSystem/table/otherDetail" */}

                      <button
                        className="bg-addDetail text-white rounded-lg md:w-40 py-3 px-4 ml-2"
                        // onClick={submitAddSystem}
                      >
                        Add Other Details
                      </button>
                      <Link to="/fbSystem/table/otherDetail">
                        <button className="bg-addDetail text-white rounded-lg md:w-40 py-3 px-4 ml-2">
                          next page
                        </button>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {/* modal */}
    </>
  );
};

export default ActiveTable;
