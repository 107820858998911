import React from "react";
import { NavLink } from "react-router-dom";
import DashboardCards from "../../../components/DashboardCards";
const Statistics = () => {
  return (
    <>
      {/* <div className="flex flex-row justify-start gap-x-4 text-2xl dashbaordNavs">
        <div className="mb-3">
          <NavLink to="/dashboard/overview" activeClassName="activeDash">
            Overview
          </NavLink>
        </div>
        <div>
          {" "}
          <NavLink to="/dashboard/statistics">Statistics</NavLink>
        </div>
      </div> */}

      <div>
        <div className="flex bg-none  flex-row gap-8 flex-wrap mt-4">
          <DashboardCards background={"dash-green"} />
          <DashboardCards background={"dash-blue"} />
          <DashboardCards background={"dash-brown"} />
          <DashboardCards background={"dash-red"} />
          <DashboardCards background={"dash-yellow"} />
          <DashboardCards background={"dash-lightblue"} />
        </div>
      </div>
    </>
  );
};

export default Statistics;
