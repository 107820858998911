import QuestionEmojiComp from "../../../components/QuestionEmojiComp";
import SearchBar from "../../../components/SearchBar";
import TableFooter from "../../../components/TableFooter";

const QuestionEmoji = () => {
  const handlePageClick = () => {};
  return (
    <div>
      <div className="flex flex-wrap gap-6 flex-row w-100 mt-3 justify-between items-center">
        <SearchBar />
      </div>

      <div className="mt-3 items-center gap-3 grid grid-cols-1 md:grid-cols-4">
        <QuestionEmojiComp />
        <QuestionEmojiComp />
        <QuestionEmojiComp />
        <QuestionEmojiComp />
        <QuestionEmojiComp />
        <QuestionEmojiComp />
        <QuestionEmojiComp />
        <QuestionEmojiComp />
      </div>

      <TableFooter handlePageClick={handlePageClick} />
    </div>
  );
};

export default QuestionEmoji;
