const AddonsComp = ({ showModal, setShowModal }) => {
  const modalFunc = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  console.log("add on modal", showModal);

  return (
    <>
      <div className="flex flex-col p-5 rounded-3xl w-full  bg-gray-200 gap-3">
        <div className="flex flex-col md:flex-row justify-between">
          <h2 className="text-xl  font-bold">Custom Emojis</h2>
          <p className="text-green-500">Payment Recieved</p>
        </div>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum,
          officia similique deleniti tenetur vel dolore optio neque repellendus
          molestias ut, quidem quo sapiente laboriosam non. Sequi repellat ipsum
          molestias cumque?
        </p>
        <div className="flex flex-col md:flex-row md:items-center md:justify-between  gap-3">
          <p>
            Price: <span className="font-bold text-lg">$100/month</span>
          </p>
          <a
            className="p-3 simple-green rounded-lg w-40 text-center text-white"
            href=""
            onClick={modalFunc}
          >
            Edit Credentials
          </a>
        </div>
      </div>

      {showModal == true ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative p-4 md:w-2/5  md:h-auto md:mt-0">
              {/* h-full class removed to modal responsive on mobile screen abvoe div */}
              {/*content*/}
              <div className="rounded-lg  p-4 shadow-lg relative flex flex-col md:w-100 bg-white outline-none focus:outline-none">
                {/*header*/}

                <div className="flex items-start justify-between  border-solid  ">
                  <h3 className="text-4xl p-2 text-black font-bold">
                    SMS and Email Notifications
                  </h3>
                  <button
                    className="p-1 ml-auto  border-0 text-white bg-modalCross rounded-full float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                {/*body*/}
                <div className="p-2 w-100 mb-5">
                  <div className="flex flex-col">
                    <p className="text-xl mt-3">
                      You will recieve all the Notifications through SMS and
                      Email. You will recieve all the Notifications through SMS
                      and Email. You will recieve all the Notifications through
                      SMS and Email
                    </p>
                    <p className="text-2xl mt-3">
                      <span className="text-black font-normal">Price</span>
                      <span className="font-medium">$100/month</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default AddonsComp;
