// import ReactPaginate from "react-paginate";
import ShowItems from "./ShowItems";
const TableFooter = (props) => {
  return (
    <div className="flex flex-row sm:justify-center flex-wrap lg:justify-end gap-6 mt-3">
      {/* <h4 className="text-base">Items per page</h4> */}
      <ShowItems />
      {/* <ReactPaginate
        className="flex flex-row gap-4 text-base"
        breakLabel="..."
        nextLabel=" >"
        initialPage={0}
        activeClassName="active-page-bg"
        activeLinkClassName="active-page-link"
        pageClassName="page-bg"
        pageLinkClassName=""
        onPageChange={props.handlePageClick}
        pageRangeDisplayed={1}
        pageCount={10}
        previousLabel="< "
        renderOnZeroPageCount={null}
      /> */}
    </div>
  );
};

export default TableFooter;
