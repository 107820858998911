import React, { useState } from "react";
import SearchBar from "../../../components/SearchBar";
import Table from "../../../components/Table";
import TableFooter from "../../../components/TableFooter";
import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import tableIcons from "../../../components/MaterialTableIcons";
import DeleteTableBtn from "../../../components/DeleteTableBtn";
import EditTableBtn from "../../../components/EditTableBtn";
const VectorTable = () => {
  const handlePageClick = () => {};
  const [modal, setModal] = useState(false);
  const showModal = () => {
    setModal(true);
  };
  const [modal2, setModal2] = useState(false);
  const showModal2 = () => {
    setModal2(true);
  };

  const columns = [
    { title: "System name", field: "name" },
    { title: "requested on", field: "company_feedbacks_id" },
    {
      title: " status",
      field: "company_feedbacks_id",
      render: (rowData) => (
        <div
          style={{
            background: "#55ac1c47",
            border: "1px solid #55AC1C",
            borderRadius: "8px",
            color: "#55AC1C",
            width: "60px",
            padding: "3px",
            textAlign: "center",
          }}
        >
          {rowData.status}
        </div>
      ),
    },
    // { title: "ACTIONS", filed: "action" },
  ];
  const action1 = "Delete";
  const action2 = "View";
  return (
    <>
      <div
        className="flex flex-wrap gap-6 flex-row w-100 mt-3 justify-end mb-4 items-center"
        id="root"
      >
        <div>
          <button
            className="gradientColor  text-white  py-3 px-4 "
            onClick={showModal}
          >
            Add Request
          </button>
        </div>
      </div>
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        title=""
        icons={tableIcons}
        columns={columns}
        options={{
          headerStyle: {
            fontWeight: "bold",
            fontSize: "15px",
            textTransform: "uppercase",
          },
          // searchFieldAlignment: "left",
          search: true,
          actionsColumnIndex: -1,
        }}
        actions={[
          {
            icon: () => <DeleteTableBtn text={action1} />,
            // tooltip: { action1 },
            onClick: (event, rowData) => {
              console.log("val", rowData.id);
            },
          },
          {
            icon: () => <EditTableBtn text={action2} />,
            // tooltip: { action2 },
            onClick: (event, rowData) => {
              console.log("val", rowData.company_feedbacks_id);
              setModal2(true);
            },
          },
          // rowData => ({
          //   icon: 'delete',
          //   tooltip: 'Delete User',
          //   onClick: (event, rowData) => confirm("You want to delete " + rowData.name),
          //   disabled: rowData.birthYear < 2000
          // })
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append(
              "Authorization",
              `Bearer ${localStorage.getItem("token")}`
            );

            var requestOptions = {
              method: "GET",
              headers: myHeaders,
              redirect: "follow",
            };
            let url = `${
              process.env.REACT_APP_BASE_URL
            }/getCompanyFeedbackSystemPivot/${localStorage.getItem(
              "company_id"
            )}?`;
            url += `page=${query.page + 1}`;
            // url += "page=" + query.page + 1;
            // url += "&page=" + (query.page + 1);
            // console.log("page", parseInt(query.page));
            fetch(url, requestOptions)
              .then((response) => response.json())
              .then((result) => {
                console.log("apicall", result);
                // setData(result.data.data);
                console.log(result.data);
                // console.log("Querry", query);
                // console.log("current pg", result.data.current_page);

                resolve({
                  data: result.data.data,
                  page: query.page,
                  totalCount: result.data.total,
                });
              })
              .catch((error) => console.log("error", error));
          })
        }
      />

      {/* modal */}
      {modal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative p-4 md:w-2/5  md:h-auto md:mt-0">
              {/* h-full class removed to modal responsive on mobile screen abvoe div */}
              {/*content*/}
              <div className="rounded-lg  p-4 shadow-lg relative flex flex-col md:w-100 bg-white outline-none focus:outline-none">
                {/*header*/}

                <div className="flex items-start justify-between  border-solid  ">
                  <button
                    className="p-1 ml-auto  border-0 text-white bg-modalCross rounded-full float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setModal(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                {/*body*/}
                <form>
                  <div className="flex flex-wrap">
                    <div className="w-full px-3 mb-6 md:mb-0">
                      <label
                        for="system Name"
                        class="block mb-2 text-2xl font-medium text-black  dark:text-gray-300"
                      >
                        Request Title
                      </label>
                      <input
                        type="systemName"
                        name="systemName"
                        id="systemName"
                        class="bg-white border border-gray-300 text-black-900 text-lg 
                                focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 
                                dark:border-gray-500 dark:placeholder-gray-400
                              dark:text-white"
                        placeholder="Enter Request Title Here"
                        required
                      />
                    </div>
                    <div class="w-full px-3 mb-6 md:mb-0">
                      <label
                        class="block tracking-wide text-black text-2xl font-medium mb-2"
                        for="grid-first-name"
                      >
                        Request Description
                      </label>
                      <textarea
                        class="resize w-full p-2.5 border border-gray-300"
                        placeholder="Enter Request Description Here"
                      ></textarea>
                    </div>
                  </div>
                  <div className="flex flex-row justify-center items-center mt-3">
                    <button className=" bg-red-500 rounded text-white py-2 px-4 ">
                      Cancel
                    </button>
                    <button className="bg-addDetail rounded text-white py-2 px-4 ml-2">
                      Submit Request
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {/* modal */}

      {/* modal2 */}
      {modal2 ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative p-4 md:w-2/5  md:h-auto md:mt-0">
              {/* h-full class removed to modal responsive on mobile screen abvoe div */}
              {/*content*/}
              <div className="rounded-lg  p-4 shadow-lg relative flex flex-col md:w-100 bg-white outline-none focus:outline-none">
                {/*header*/}

                <div className="flex items-start justify-between  border-solid ">
                  <h3 className="text-4xl p-2 text-black font-bold">
                    Add Development Vector
                  </h3>
                  <button
                    className="p-1 ml-auto  border-0 text-white bg-modalCross rounded-full float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setModal2(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                {/*body*/}
                <div className="p-2 w-100">
                  <div className="flex flex-col">
                    <p className="text-xl mt-3">
                      Add Development Vector in the vector pack because we need
                      to add this vector in our question. Add Development Vector
                      in the vector pack because we need to add this vector in
                      our question
                    </p>
                    <p className="text-2xl mt-5 mb-4">
                      <span className="font-medium">Requested on:</span>
                      <span className="text-black font-normal">19/1/2022</span>
                    </p>
                  </div>

                  <div className="flex flex-row justify-center mt-3">
                    <button className=" bg-red-600 rounded text-white py-2 px-4 ">
                      Delete
                    </button>
                    <button className="editGradient text-white py-2 px-4 ml-2">
                      Okay
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {/* modal2 */}
    </>
  );
};

export default VectorTable;
