const ShowItems = () => {
  return (
    <h4 className="text-base">
      Items per page{" "}
      <span>
        <select
          className="form-select w-22
                      
                        py-2.2
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding bg-no-repeat
                        border-b border-solid border-gray-600
                        
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          aria-label="Default select example"
        >
          <option defaultValue={15}>15</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
        </select>
      </span>{" "}
    </h4>
  );
};

export default ShowItems;
