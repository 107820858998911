import demo from "../images/QuestionEmoji.png";

const QuestionEmojiComp = () => {
  return (
    <div className="rounded-2xl p-5 border  border-slate-300 text-center gap-3 items-center flex flex-col">
      <img className="h-44 w-44" src={demo} alt="" />
      <h2 className="text-lg font-bold">Wonderful Emoji</h2>
      <div className="flex w-full items-center   flex-row gap-2">
        <a
          className="text-sm rounded-full flex-grow text-white redAction-btn py-1 px-2"
          href=""
        >
          Delete
        </a>
        <a
          className="text-sm rounded-full flex-grow text-white greenAction-btn py-1 px-2"
          href=""
        >
          Edit
        </a>
      </div>
    </div>
  );
};

export default QuestionEmojiComp;
