import React from "react";

import Overview from "./Overview";
import Statistics from "./Statistics";
import { Switch, Route, NavLink } from "react-router-dom";

const Dashboard = () => {
  return (
    <>
      <div className="flex flex-row justify-start gap-x-4 text-2xl dashbaordNavs">
        <div className="mb-3">
          <NavLink
            exact
            activeStyle={{
              textDecoration: "underline",
              background: "none",
              color: "black",
            }}
            to="/dashboard"
          >
            Overview
          </NavLink>
        </div>
        <div>
          {" "}
          <NavLink
            exact
            activeStyle={{
              textDecoration: "underline",
              background: "none",
              color: "black",
            }}
            to="/dashboard/stats"
          >
            Statistics
          </NavLink>
        </div>
      </div>

      <Switch>
        <Route path="/dashboard/stats" component={Statistics} />
        <Route path="/dashboard" component={Overview} />
      </Switch>
    </>
  );
};

export default Dashboard;
