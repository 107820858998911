import toast, { Toaster } from "react-hot-toast";
import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";
import DashboardSu from "./pages/Superadmin/Dashboard/Dashboard";
import "./App.css";
import Registration from "./pages/Registration";
import SideBarSu from "./components/SideBarSuperAdmin";
import ActiveBrands from "./pages/Superadmin/Brands/ActiveBrands";
import InactiveBrands from "./pages/Superadmin/Brands/InactiveBrands";
import BrandRequests from "./pages/Superadmin/Brands/BrandRequests";
import Addons from "./pages/Superadmin/Addons/Addons";
import DefaultQuestions from "./pages/Superadmin/FeedbackSystem/DefaultQuestions";
import QuestionVectors from "./pages/Superadmin/FeedbackSystem/QuestionVectors";
import QuestionEmoji from "./pages/Superadmin/FeedbackSystem/QuestionEmoji";
import VectorAdditionRequests from "./pages/Superadmin/FeedbackSystem/VectorAdditionRequests";
import ViewAddonsSU from "./pages/Superadmin/Addons/ViewAddons";
import PaymentRequests from "./pages/Superadmin/PaymentRequests/PaymentRequests";
import Industries from "./pages/Superadmin/Industries/Industries";
import FaqsSU from "./pages/Superadmin/Help/Faqs";
import HelpRequests from "./pages/Superadmin/Help/HelpRequests";
import SuggestionRequests from "./pages/Superadmin/Help/SuggestionRequests";
import ComplainRequests from "./pages/Superadmin/Help/ComplainRequests";
import Wrapper from "./components/Wrapper";
import OtherDetailTable from "./pages/Admin/FeedbackSystem/OtherDetailTable";
import ActiveTable from "./pages/Admin/FeedbackSystem/ActiveTable";
import AddQuestion from "./pages/Admin/FeedbackSystem/AddQuestion";
import WelcomeScreen from "./pages/Admin/WelcomeScreen/WelcomeScreen";
import TakerDataTable from "./pages/Admin/FeedBackTakers/TakerDataTable";
import FblistTable from "./pages/Admin/FeedbackList/FblistTable";
import FblistRequest from "./pages/Admin/FeedbackList/FblistRequest";
import Suggestions from "./pages/Admin/Help/Suggestions";
import RequesthelpTable from "./pages/Admin/Help/RequesthelpTable";
import Faqs from "./pages/Admin/Help/Faq";
import Complain from "./pages/Admin/Help/ComplainTable";
import VectorTable from "./pages/Admin/VectorRequest/VectorTable";
import PaymentTable from "./pages/Admin/PaymentHistory/PaymentTable";
import SuggestionTable from "./pages/Admin/Help/SuggestionTable";
import SideBar from "./components/SideBarAdmin";
import ViewAddons from "./pages/Admin/Addons/ViewAddons";

import Dashboard from "./pages/Admin/Dashboard/Dashboard";
import WrapperSu from "./components/WrapperSu";
import Login from "./pages/Login";
import ResetPw from "./pages/ResetPw";
import ForgotPw from "./pages/ForgotPw";
import EmailSet from "./pages/EmailSet";
import Superadmin from "./protected-routes/Superadmin";
import Admin from "./protected-routes/Admin";

function App() {
  return (
    <Router>
      <Toaster />
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/registration" component={Registration} />
        <Route exact path="/reset-pw" component={ResetPw} />
        <Route exact path="/email-sent" component={EmailSet} />
        <Route exact path="/forget-pw" component={ForgotPw} />

        <Superadmin
          path="/su/feedback-system/default-questions"
          component={DefaultQuestions}
        />
        <Superadmin
          path="/su/feedback-system/question-vectors"
          component={QuestionVectors}
        />
        <Superadmin
          path="/su/feedback-system/question-emoji"
          component={QuestionEmoji}
        />
        <Superadmin
          path="/su/feedback-system/vector-add-req"
          component={VectorAdditionRequests}
        />
        <Superadmin
          path="/su/feedback-system/view-addons"
          component={ViewAddonsSU}
        />

        <Superadmin path="/su/add-ons" component={Addons} />

        <Superadmin path="/su/help/faqs" component={FaqsSU} />
        <Superadmin path="/su/help/help-requests" component={HelpRequests} />
        <Superadmin
          path="/su/help/suggestion-requests"
          component={SuggestionRequests}
        />
        <Superadmin
          path="/su/help/complain-requests"
          component={ComplainRequests}
        />

        {/* Payment Requests */}
        <Superadmin path="/su/brands/active-brands" component={ActiveBrands} />
        <Superadmin
          path="/su/brands/inactive-brands"
          component={InactiveBrands}
        />
        <Superadmin
          path="/su/brands/brand-requests"
          component={BrandRequests}
        />

        <Superadmin path="/su/payment-req" component={PaymentRequests} />

        <Superadmin path="/su/dashboard" component={DashboardSu} />

        <Superadmin path="/su/industries" component={Industries} />

        <Admin path="/dashboard" component={Dashboard} />
        {/* <Admin path="/dashboard/stats" component={Statistics} /> */}
        {/* <Admin path="/dashboard/overview" component={Overview} /> */}
        <Admin path="/wrapper" component={Wrapper} />
        <Admin path="/fbSystem/table" exact component={ActiveTable} />
        <Admin
          path="/fbSystem/table/otherDetail"
          component={OtherDetailTable}
        />
        <Admin path="/fbSystem/table/addQuestion" component={AddQuestion} />
        <Admin path="/welcomeScreen" component={WelcomeScreen} />
        <Admin path="/fbtaker/datatable" component={TakerDataTable} />
        <Admin path="/fbList/datatable" exact component={FblistTable} />
        <Admin path="/fbList/datatable/request" component={FblistRequest} />
        <Admin path="/addOns" component={ViewAddons} />

        <Admin path="/help/faqs" component={Faqs} />
        <Admin path="/help/complain" component={Complain} />
        <Admin path="/help/suggestion" component={Suggestions} />
        <Admin path="/help/suggestionTable" component={SuggestionTable} />
        <Admin path="/help/requestTable" component={RequesthelpTable} />
        <Admin path="/payment/table" component={PaymentTable} />
        <Admin path="/vector/table" component={VectorTable} />
      </Switch>
    </Router>
  );
}

export default App;
