import React from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
];

const COLORS = ["#398F91", "#57B47F", "#1B4A50"];
const onPieEnter = (e) => {
  //console.log(e);
  if (e) {
    let toolTipWrapper = document.getElementsByClassName(
      "recharts-tooltip-wrapper"
    )[0];
    toolTipWrapper.style.transition = "transform 400ms ease 0s";
    toolTipWrapper.style.transform =
      "translate(" + (e.chartX + 10) + "px, " + (e.chartY + 10) + "px)";
  }
};

const Piechart = () => {
  return (
    <>
      <div className="flex flex-col p-5 rounded-3xl bg-gray-100 gap-5">
        <p className="text-2xl text-changeBtn font-medium">Your Pie Chart</p>
        <div className=" md:w-100 h-80">
          <ResponsiveContainer>
            <PieChart width={400} height={400}>
              <Tooltip />
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={130}
                fill="#8884d8"
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
};

export default Piechart;
