import React, { useState } from "react";
import SearchBar from "./SearchBar";
import Table from "./Table";
import edit from "../images/editPencil.svg";
import del from "../images/delTrash.svg";
import TableFooter from "./TableFooter";
import brandImage from "./../images/brandImage.png";
import brandImage2 from "./../images/brandImage2.png";
import { Link } from "react-router-dom";

const AddonsTable = () => {
  const handlePageClick = () => {};

  const [modal, setModal] = useState(false);
  const showModal = () => {
    console.log("MODAL Clicked");
    setModal(true);
  };
  console.log("Modal = ", modal);
  return (
    <>
      <div className="flex flex-wrap gap-6 flex-row w-100 mt-3 justify-between items-center">
        <SearchBar />
      </div>

      <Table>
        <thead className="bg-white border-b ">
          <tr className="border-b border-black uppercase">
            <th
              scope="col"
              className="text-base font-medium text-gray-900 px-6 py-4 text-left"
            ></th>
            <th
              scope="col"
              className="text-base font-medium text-gray-900 px-6 py-4 text-left"
            >
              Brand Name
            </th>
            <th
              scope="col"
              className="text-base font-medium text-gray-900 px-6 py-4 text-left"
            >
              Created Date
            </th>
            <th
              scope="col"
              className="text-base font-medium text-gray-900 px-6 py-4 text-left"
            >
              Brand ID
            </th>
            <th
              scope="col"
              className="text-base font-medium text-gray-900 px-6 py-4 text-left"
            >
              Joining Date
            </th>
            <th
              scope="col"
              className="text-base font-medium text-gray-900 px-6 py-4 text-left"
            >
              Category
            </th>
            <th
              scope="col"
              className="text-base text-left font-medium text-gray-900 px-6 py-4 "
            >
              Actions
            </th>
            <th
              scope="col"
              className="text-base font-medium text-gray-900 px-6 py-4 text-left"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr className="font-bold">
            <td className="px-6 py-4  text-sm font-medium text-gray-900">
              <img src={brandImage} alt="" />
            </td>
            <td className="px-6 py-4  text-sm font-medium text-gray-900">
              Hotel dotcom
            </td>
            <td className="text-sm text-gray-900 font-light px-6 py-4 ">
              14-Feb-2022
            </td>
            <td className="text-sm text-gray-900 font-light px-6 py-4 ">10</td>
            <td className="text-sm text-gray-900 font-light px-6 py-4 ">
              F179YHU8
            </td>
            <td className="text-sm text-gray-900 font-light px-6 py-4 ">
              14-Feb-2022
            </td>
            <td className="text-sm text-gray-900 font-light px-6 py-4 ">
              Restaurant
            </td>
            <td className="text-sm text-gray-900 font-light px-6 py-4 ">
              <div className="flex flex-row gap-3">
                <Link
                  to="/feedback-system/view-addons"
                  className="btn greenAction-btn p-2 rounded-md text-white"
                >
                  VIEW ADD ONS
                </Link>
              </div>
            </td>
          </tr>
          <tr className="font-bold">
            <td className="px-6 py-4  text-sm font-medium text-gray-900">
              <img src={brandImage2} alt="" />
            </td>
            <td className="px-6 py-4  text-sm font-medium text-gray-900">
              Hotel dotcom
            </td>
            <td className="text-sm text-gray-900 font-light px-6 py-4 ">
              14-Feb-2022
            </td>
            <td className="text-sm text-gray-900 font-light px-6 py-4 ">10</td>
            <td className="text-sm text-gray-900 font-light px-6 py-4 ">
              F179YHU8
            </td>
            <td className="text-sm text-gray-900 font-light px-6 py-4 ">
              14-Feb-2022
            </td>
            <td className="text-sm text-gray-900 font-light px-6 py-4 ">
              Travel Services
            </td>
            <td className="text-sm text-gray-900 font-light px-6 py-4 ">
              <div className="flex flex-row gap-3">
                <Link
                  href=""
                  className="btn greenAction-btn p-2 rounded-md text-white"
                >
                  VIEW ADD ONS
                </Link>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
      <TableFooter handlePageClick={handlePageClick} />
      {/* modal */}
      {modal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative p-4 w-full md:w-5/12 h-full md:h-auto">
              {/*content*/}
              <div className="rounded-lg  p-4 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}

                <div className="flex items-start justify-between  border-solid  ">
                  <button
                    className="p-1 ml-auto  border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setModal(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                {/*body*/}
                <div className="p-2">
                  <form>
                    <div class="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full px-3 mb-6 md:mb-0">
                        <label
                          for="system Name"
                          class="block mb-2 text-2xl font-medium text-black  dark:text-gray-300"
                        >
                          System Name
                        </label>
                        <input
                          type="systemName"
                          name="systemName"
                          id="systemName"
                          class="bg-white border border-gray-300 text-black-900 text-lg 
                                focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 
                                dark:border-gray-500 dark:placeholder-gray-400
                              dark:text-white"
                          placeholder="Service Feedback System"
                          required
                        />
                      </div>
                      <p className="text-3xl font-semibold py-4 px-3 mb-6 md:mb-0">
                        UNIQUE FEEDBACK NUMBER
                      </p>
                      <div class="w-full md:w-1/2 px-3 mb-3 md:mb-0">
                        <label
                          class="block tracking-wide text-black text-lg font-medium"
                          for="grid-first-name"
                        >
                          Name
                        </label>
                        <input
                          class="appearance-none block w-full text-black border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                          id="grid-first-name"
                          type="text"
                          placeholder="Table ID"
                        />
                      </div>
                      <div class="w-full md:w-1/2 px-3 mb-5">
                        <label
                          class="block tracking-wide text-black text-lg font-medium"
                          for="grid-first-name"
                        >
                          Type
                        </label>
                        <div class="relative">
                          <select
                            class="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-state"
                            placeholder="Numeric/AlphaNumeric"
                          >
                            <option value="" disabled selected>
                              Numeric/AlphaNumeric
                            </option>
                            <option>Option 1</option>
                            <option>Option 2</option>
                            <option>Option 3</option>
                          </select>
                          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg
                              class="fill-current h-4 w-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div class="w-full md:w-1/2 px-3 mb-5">
                        <label
                          class="block tracking-wide text-black text-lg mb-2 font-medium"
                          for="grid-first-name"
                        >
                          Selection Type
                        </label>
                        <div class="relative">
                          <select
                            class="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-state"
                            placeholder="Numeric/AlphaNumeric"
                          >
                            <option value="" disabled selected>
                              Numeric/AlphaNumeric
                            </option>
                            <option>Option 1</option>
                            <option>Option 2</option>
                            <option>Option 3</option>
                          </select>
                          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg
                              class="fill-current h-4 w-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label
                          class="block tracking-wide text-black text-lg mb-2 font-medium"
                          for="grid-first-name"
                        >
                          Number of IDs
                        </label>
                        <input
                          class="appearance-none block w-full text-black border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                          id="grid-first-name"
                          type="text"
                          placeholder="15"
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6"></div>

                    <div class="flex flex-wrap -mx-3 mb-2"></div>
                    <div className="flex flex-row justify-center">
                      <button className=" bg-cancelBtn text-white rounded-lg md:w-40 py-3 px-4 ">
                        Cancel
                      </button>
                      <button className="bg-addDetail text-white rounded-lg md:w-40 py-3 px-4 ml-2">
                        Add Other Details
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {/* modal */}
    </>
  );
};

export default AddonsTable;
