import React, { useState } from "react";
import SearchBar from "../../../components/SearchBar";
import Table from "../../../components/Table";
import TableFooter from "../../../components/TableFooter";
import axios from "axios";
import { Paper } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TakerSchema } from "../../../FormSchemas/TakerAddSchema";
import MaterialTable from "material-table";
import tableIcons from "../../../components/MaterialTableIcons";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import DeleteTableBtn from "../../../components/DeleteTableBtn";
import EditTableBtn from "../../../components/EditTableBtn";
import { de } from "date-fns/locale";
const TakerDataTable = () => {
  const handlePageClick = () => {};

  const [modal, setModal] = useState(false);
  const showModal = () => {
    console.log("MODAL Clicked");
    setModal(true);
  };
  console.log("Modal = ", modal);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(TakerSchema),
  });

  const headers = {
    // "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "http://localhost:3000",
    // // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    // crossorigin: true,
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  const submitForm = async (data) => {
    // console.log("data:", data);

    let data2 = {
      company_id: localStorage.getItem("company_id"),
    };
    const myData = { ...data, ...data2 };

    console.log("dataMod:", myData);
    // const url = `${process.env.REACT_APP_BASE_URL}/createFeedBackTakerId`;
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/createFeedBackTakerId`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: myData,
    };

    axios(config)
      .then(function (response) {
        console.log("success !!");
        console.log(JSON.stringify(response.data));
        setModal(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const columns = [
    { title: "S.No", field: "name" },
    { title: "Feedback Taker name", field: "company_feedbacks_id" },
    { title: "Feedback Taker ID", field: "company_feedbacks_id" },
    {
      title: "Created Date",
      field: "created_at",
      render: (rowData) => rowData.created_at.split("T").slice(0, 1),
    },

    // { title: "ACTIONS", filed: "action" },
  ];

  const action1 = "Delete";
  const action2 = "Edit";

  return (
    <>
      <div className="flex flex-wrap gap-6 flex-row w-100 mt-3 justify-end items-center">
        <div>
          <button
            className="gradientColor  text-white  py-3 px-4 mb-4"
            onClick={showModal}
          >
            Add Feedback Taker
          </button>
        </div>
      </div>
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        title=""
        icons={tableIcons}
        columns={columns}
        options={{
          headerStyle: {
            fontWeight: "bold",
            fontSize: "15px",
            textTransform: "uppercase",
          },
          // searchFieldAlignment: "left",
          search: true,
          actionsColumnIndex: -1,
        }}
        actions={[
          {
            icon: () => <DeleteTableBtn text={action1} />,
            // tooltip: "Delete",
            onClick: (event, rowData) => {
              console.log("val", rowData.id);
            },
          },
          {
            icon: () => <EditTableBtn text={action2} />,
            // tooltip: "Edit",
            onClick: (event, rowData) => {
              console.log("val", rowData.company_feedbacks_id);
              setModal(true);
            },
          },
          // rowData => ({
          //   icon: 'delete',
          //   tooltip: 'Delete User',
          //   onClick: (event, rowData) => confirm("You want to delete " + rowData.name),
          //   disabled: rowData.birthYear < 2000
          // })
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append(
              "Authorization",
              `Bearer ${localStorage.getItem("token")}`
            );

            var requestOptions = {
              method: "GET",
              headers: myHeaders,
              redirect: "follow",
            };
            let url = `${
              process.env.REACT_APP_BASE_URL
            }/getCompanyFeedbackSystemPivot/${localStorage.getItem(
              "company_id"
            )}?`;
            url += `page=${query.page + 1}`;
            // url += "page=" + query.page + 1;
            // url += "&page=" + (query.page + 1);
            // console.log("page", parseInt(query.page));
            fetch(url, requestOptions)
              .then((response) => response.json())
              .then((result) => {
                console.log("apicall", result);
                // setData(result.data.data);
                console.log(result.data);
                // console.log("Querry", query);
                // console.log("current pg", result.data.current_page);

                resolve({
                  data: result.data.data,
                  page: query.page,
                  totalCount: result.data.total,
                });
              })
              .catch((error) => console.log("error", error));
          })
        }
      />

      {/* modal */}
      {modal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative p-4   md:w-3/12  md:h-auto">
              {/* h-full is removed so that in mobile view modal appears in middle of screen */}
              {/*content*/}
              <div className="rounded-lg  p-4 shadow-lg relative flex flex-col md:w-100 bg-white outline-none focus:outline-none">
                {/*header*/}

                <div className="flex items-start justify-between  border-solid  ">
                  <button
                    className="p-1 ml-auto  border-0 text-white bg-modalCross rounded-full float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setModal(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                {/*body*/}
                <div className="p-2">
                  <form onSubmit={handleSubmit(submitForm)}>
                    <div class="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full px-3 mb-6 md:mb-0">
                        <label
                          htmlFor="system Name"
                          className="block mb-2 text-2xl font-medium text-black  dark:text-gray-300"
                        >
                          Feedback Taker Name
                        </label>
                        <input
                          type="name"
                          name="name"
                          id="name"
                          className="bg-white border border-gray-300 text-black-900 text-lg 
                              focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 
                              dark:border-gray-500 dark:placeholder-gray-400
                            dark:text-white"
                          placeholder="Patty O'Furniture."
                          required
                          {...register("name")}
                        />
                        <p className="text-sm text-red-700 ml-3">
                          {" "}
                          {errors.name?.message}{" "}
                        </p>
                        <label
                          htmlFor="system Name"
                          className="block mb-2 text-2xl font-medium text-black  dark:text-gray-300"
                        >
                          Feedback Taker ID
                        </label>
                        <input
                          type="company_id"
                          name="company_id"
                          id="company_id"
                          className="bg-white border border-gray-300 text-black-900 text-lg 
                              focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 
                              dark:border-gray-500 dark:placeholder-gray-400
                            dark:text-white"
                          placeholder="NDIA7234N"
                          required
                          // {...register("company_id")}
                        />
                        <p className="text-sm text-red-700 ml-3">
                          {" "}
                          {errors.company_id?.message}{" "}
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-2"></div>
                    <div className="flex flex-row justify-center">
                      <button className=" bg-cancelBtn text-white rounded-lg md:w-40 py-3 px-4 ">
                        Cancel
                      </button>
                      <button className="bg-addDetail text-white rounded-lg md:w-40 py-3 px-4 ml-2">
                        Add
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {/* modal */}
    </>
  );
};

export default TakerDataTable;
