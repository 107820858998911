import React, { useState } from "react";
import SearchBar from "../../../components/SearchBar";
import Table from "../../../components/Table";
import TableFooter from "../../../components/TableFooter";
import MaterialTable from "material-table";
import tableIcons from "../../../components/MaterialTableIcons";
import { queryByRole, render } from "@testing-library/react";
import { Paper } from "@material-ui/core";
import Save from "@material-ui/icons/Save";
import FindInPageOutlinedIcon from "@material-ui/icons/FindInPageOutlined";
import DeleteTableBtn from "../../../components/DeleteTableBtn";
import EditTableBtn from "../../../components/EditTableBtn";
const PaymentTable = () => {
  // const handlePageClick = () => {};
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const showModal = () => {
    console.log("MODAL Clicked");
    setModal(true);
  };

  // console.log("Modal val", modal);
  const columns = [
    { title: "S.NO", field: "id" },
    {
      title: "PAYMENT title",
      field: "Payment title",
      render: (rowData) => rowData.payment_date.split(" ").slice(0, 1),
    },
    {
      title: "PAYMENT DATE",
      field: "payment_date",
      render: (rowData) => rowData.payment_date.split(" ").slice(0, 1),
    },
    {
      title: "PAYMENT DUE DATE",
      field: "due_date",
      render: (rowData) => rowData.payment_date.split(" ").slice(0, 1),
    },
    {
      title: "STATUS",
      field: "status",
      render: (rowData) => (
        <div
          style={{
            background: "#55ac1c47",
            border: "1px solid #55AC1C",
            borderRadius: "8px",
            color: "#55AC1C",
            width: "60px",
            padding: "3px",
            textAlign: "center",
          }}
        >
          {rowData.status}
        </div>
      ),
    },
    // { title: "ACTIONS", filed: "action" },
  ];
  const action1 = "Pay";
  const action2 = "View";
  // console.log("data state =>", data);
  return (
    <>
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        title="Payment History"
        icons={tableIcons}
        // columns={[
        //   {
        //     // title: "Avatar",
        //     // field: "avatar",
        //     render: (rowData) => (
        //       <img
        //         style={{ height: 36, borderRadius: "50%" }}
        //         src={rowData.avatar}
        //       />
        //     ),
        //   },
        //   { title: "Id", field: "id" },
        //   { title: "First Name", field: "first_name" },
        //   { title: "Last Name", field: "last_name" },
        // ]}
        columns={columns}
        options={{
          headerStyle: {
            fontWeight: "bold",
            fontSize: "15px",
            textTransform: "uppercase",
          },
          search: true,
          actionsColumnIndex: -1,
        }}
        actions={[
          {
            icon: () => <DeleteTableBtn text={action1} />,
            tooltip: "View",
            onClick: (event, rowData) => {
              console.log("val", rowData.company_id);
              setModal(true);
            },
          },
          {
            icon: () => <EditTableBtn text={action2} />,
            // tooltip: { action2 },
            onClick: (event, rowData) => {
              console.log("val", rowData.company_feedbacks_id);
            },
          },
          // rowData => ({
          //   icon: 'delete',
          //   tooltip: 'Delete User',
          //   onClick: (event, rowData) => confirm("You want to delete " + rowData.name),
          //   disabled: rowData.birthYear < 2000
          // })
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append(
              "Authorization",
              `Bearer ${localStorage.getItem("token")}`
            );

            var requestOptions = {
              method: "GET",
              headers: myHeaders,
              redirect: "follow",
            };
            let url = `${
              process.env.REACT_APP_BASE_URL
            }/getCompanyPayments/${localStorage.getItem("company_id")}?`;
            url += "page=" + query.page + 1;
            // url += "&page=" + (query.page + 1);
            console.log("page", parseInt(query.page));
            fetch(url, requestOptions)
              .then((response) => response.json())
              .then((result) => {
                console.log("apicall", result.data.data);
                // setData(result.data.data);
                console.log(result.data.current_page);
                console.log("Querry", query);
                resolve({
                  data: result.data.data,
                  page: query.page,
                  totalCount: result.data.total,
                });
              })
              .catch((error) => console.log("error", error));
            // .then((result) => {
            //   console.log("res", result);
            //   resolve({
            //     data: result.data,
            //     page: result.page - 1,
            //     totalCount: result.total,
            //   });
            // });
          })
        }
      />

      {/* modal */}
      {modal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative p-4 md:w-2/5  md:h-auto md:mt-0">
              {/* h-full class removed to modal responsive on mobile screen abvoe div */}
              {/*content*/}
              <div className="rounded-lg  p-4 shadow-lg relative flex flex-col md:w-100 bg-white outline-none focus:outline-none">
                {/*header*/}

                <div className="flex items-start justify-between  border-solid  ">
                  <h3 className="text-4xl p-2 text-black font-bold">
                    Unlimited Feedbacks
                  </h3>
                  <button
                    className="p-1 ml-auto  border-0 text-white bg-modalCross rounded-full float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setModal(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                {/*body*/}
                <div className="p-2 w-100">
                  <div className="flex flex-col">
                    <p className="text-2xl mt-3">
                      <span className="font-medium">Payment Date:</span>
                      <span className="text-black font-normal"> 19/1/2022</span>
                    </p>
                    <p className="text-2xl mt-3">
                      <span className="font-medium">Payment Due Date:</span>
                      <span className="text-black font-normal">19/1/2022</span>
                    </p>
                    <p className="text-2xl mt-3">
                      <span className="font-medium">Payment Status:</span>
                      <span className="text-green-500 font-normal"> PAID</span>
                    </p>
                  </div>
                  <div className="flex flex-row">
                    <input
                      type="file"
                      className="block w-full text-sm text-slate-700 mt-3
                          file:mr-4 file:py-2 file:px-4
                          file:rounded file:border-0
                          file:text-sm file:font-semibold
                          file:bg-attachFile file:text-white
                          hover:file:bg-green-500
                        "
                    />
                  </div>
                  <div className="flex flex-row justify-center mt-3">
                    <button className=" bg-red-500 rounded text-white py-2 px-4 ">
                      Discard
                    </button>
                    <button className="editGradient text-white py-2 px-4 ml-2">
                      Submit Payment
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {/* modal */}
    </>
  );
};

export default PaymentTable;
