import * as yup from "yup";

export const userSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email is required"),
  company_name: yup.string().required("Organization is required"),
  type_id: yup.string().required("Industry is required"),
  contact_no: yup
    .string()
    .required("Phone number is required")
    .matches(
      /^((0))(3)([0-9]{2})(-?)([0-9]{7})$/,
      "Your number should be in the format of 03xx-xxxxxxx"
    ),
  // .matches(/^[aA-zZ\s]+$/, "YAAAAS"),

  // password: yup
  //   .string()
  //   .required("Password is required")
  //   .matches(
  //     /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
  //     "Your password must be more than 8 characters long, should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric and 1 special character"
  //   ),
  // cnic: yup
  //   .string()
  //   .required("CNIC is required")
  //   .matches(
  //     /^[0-9]{5}-[0-9]{7}-[0-9]$/,
  //     "CNIC No must follow the XXXXX-XXXXXXX-X format"
  //   ),

  // password_confirmation: yup
  //   .string()
  //   .oneOf([yup.ref("password"), null], "Passwords must match"),

  // JoiningDate: yup.date(),
  // role: yup.string(),
});
