import React, { useState } from "react";
import Barchart from "./Barchart";
import Linegraph from "./Linegraph";
import Piechart from "./Piechart";
import RevenueGraph from "./RevenueGraph";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";
const Overview = () => {
  const [stat, setStat] = useState(false);
  const showStatss = () => {
    setStat(true);
  };

  // console.log("stat val", stat);
  return (
    <>
      {/* <div className="flex flex-row justify-start gap-x-4 text-2xl dashbaordNavs">
        <div className="mb-3">
          <NavLink to="/dashboard/overview">Overview</NavLink>
        </div>
        <div>
          {" "}
          <NavLink to="/dashboard/statistics">Statistics</NavLink>
        </div>
      </div> */}

      <div className="graphContainer items-center mt-3 grid grid-cols-1 md:grid-cols-2 gap-7">
        <Linegraph />
        <RevenueGraph />
        <Barchart />
        <Piechart />
      </div>

      {/* <Router>
        <Switch>
          <Route path="/dashboard/stats" component={Statistics} />
          <Statistics />
        </Switch>
      </Router> */}
    </>
  );
};

export default Overview;
