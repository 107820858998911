import { useState } from "react";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Modal from "./Modal";

const DashboardCards = (props) => {
  const [showModal, SetShowModal] = useState(false);

  return (
    <div className="test">
      <Modal
        show={showModal}
        close={() => {
          SetShowModal(false);
        }}
      >
        <h1>hello</h1>
        <h2>this is a modal</h2>
      </Modal>
      <div
        className={`flex flex-col gap-2 p-4  text-white rounded-2xl ${props.background}`}
      >
        <h3 className="text-1 xl font-bold ">Premium Brands</h3>
        <h2 className="text-3xl font-bold">62</h2>
        <p
          onClick={() => {
            SetShowModal(true);
            console.log(showModal);
          }}
          className="cursor-pointer"
        >
          Last updated: 14 Mins Ago
        </p>
      </div>
    </div>
  );
};

export default DashboardCards;
