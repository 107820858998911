const Table = (props) => {
    return (
        <div className="flex flex-col">
            <div className="overflow-x-auto">
                <div className="py-2 inline-block min-w-full sm:px-6  lg:px-0">
                    <div className="overflow-hidden">
                        <table className="min-w-full overscroll-auto">
                            {props.children}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Table;
