import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../images/FmbLogo.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import * as yup from "yup";
import { loginSchema } from "../FormSchemas/LoginSchema";
import { useEffect, useState } from "react";
import axios from "axios";
import { Oval } from "react-loader-spinner";
const Login = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const notifyError = (text) => {
    toast.error(text);
  };
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "http://localhost:3000",
    // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    crossorigin: true,
  };
  const history = useHistory();

  const submitForm = async (data) => {
    // console.log("data:", data);

    setLoading(true);

    if (!disabled) {
      setDisabled(true);

      const url = `${process.env.REACT_APP_BASE_URL}/login`;

      // , {
      //   loading: "Loading...",
      //   success: "Everything went smoothly.",
      //   error: "Uh oh, there was an error!",
      // });

      await axios
        .post(
          url,
          data,
          { withCredentials: true },
          {
            headers: headers,
            // withCredentials: true,
          }
        )

        .then((res) => {
          console.log(res);
          // console.log(res.data.data.role);
          // console.log("Success !!");
          setDisabled(false);
          setLoading(false);
          // console.log("name", res.data.data.user.name);
          localStorage.setItem("company_id", res.data.data.user.company_id);
          localStorage.setItem("name", res.data.data.user.name);
          localStorage.setItem("token", res.data.data.token);
          localStorage.setItem("role", res.data.data.role);
          if (localStorage.getItem("role") === "superadmin") {
            history.push("/su/dashboard");
          } else {
            history.push("/dashboard");
            console.log("admin hai");
          }

          //  notify();
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          notifyError(error.message);
          // notifyError();
        });
    }
  };

  // const callFunction = submitForm();
  // toast.promise(callFunction, {
  //   loading: "Process",
  //   error: "error occurs in data",
  //   success: "get data successfully....",
  // });

  return (
    <div className="flex flex-col gap-3 justify-content-center items-center">
      <img className="logo h-32 w-32" src={logo} alt="" />
      <div className="text-center">
        <h1 className="text-3xl font-bold">Sign In</h1>
        <h1 className="text-xl font-light">Access Portal</h1>
      </div>
      <form className=" md:w-1/4" onSubmit={handleSubmit(submitForm)}>
        {/* <input type="hidden" name="csrf-token" value={token} /> */}
        {/* <input type="hidden" name="_token" value={token}></input> */}
        <div className="flex flex-col">
          <label className="text-lg font-bold" htmlFor="">
            Email
          </label>
          <input
            type="email"
            placeholder="Enter Email"
            className="px-5 py-3 rounded-xl input-field bg-white rounded-0 col-md-3 mt-3"
            {...register("email")}
          />
          <p className="text-sm text-red-700 ml-3"> {errors.email?.message} </p>
        </div>
        <div className="flex mt-3 flex-col">
          <label className="text-lg font-bold" htmlFor="">
            Password
          </label>
          <input
            type="password"
            placeholder="Enter Password"
            className="px-5 py-3 rounded-xl input-field  rounded-0 col-md-3 mt-3"
            {...register("password")}
          />
          <p className="text-sm text-red-700 ml-3">
            {" "}
            {errors.password?.message}{" "}
          </p>
        </div>
        <div className="flex flex-col justify-center text-center items-center">
          <input
            className="sign-in py-3 px-10 mt-7 text-center text-white rounded-xl"
            type="submit"
            value="Sign In"
          />
        </div>
        <div className="flex flex-row justify-center mt-4">
          {loading ? (
            <>
              <Oval color="#2f807c" height={50} width={50} />
            </>
          ) : (
            ""
          )}
        </div>
      </form>
      <div className="text-center flex flex-col  font-light">
        <p className="text-base">Don't have an account yet?</p>
        <a
          onClick={() => {
            history.push("/registration");
          }}
          href=""
          className="text-blue-500 text-base underline"
        >
          Sign up
        </a>

        <a
          onClick={() => {
            history.push("/forget-pw");
          }}
          className="mt-3 text-blue-500 text-sm"
          href=""
        >
          Forgot Password?
        </a>
      </div>
    </div>
  );
};

export default Login;
