import React, { useEffect, useState } from "react";
import arrowLeft from "../../../images/FeedBackSystem/arrowLeft.svg";
import Switch from "../../../components/switch";
import quesVector from "../../../images/FeedBackSystem/quesVector.png";
import happyEmo from "../../../images/FeedBackSystem/happyEmo.png";
import sadEmo from "../../../images/FeedBackSystem/sadEmo.png";
import angryEmo from "../../../images/FeedBackSystem/angryEmo.png";
import smileEmo from "../../../images/FeedBackSystem/smileEmo.png";
import averageEmo from "../../../images/FeedBackSystem/averageEmo.png";
import editPencil from "../../../images/FeedBackSystem/editPencil.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddQuestionSchema } from "../../../FormSchemas/AddQuestionSchema";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const AddQuestion = () => {
  const formSubmit = () => {};
  const [positiveVal, setPositiveVal] = useState({});
  const [negativeVal, setNegativeVal] = useState({});
  const [test, setTest] = useState({});
  const history = useHistory();
  const fd = new FormData();
  const [fileUpload, setFileupload] = useState();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AddQuestionSchema),
    defaultValues: {
      negative_thresold: {},
      positive_thresold: {},
      company_id: localStorage.getItem("company_id"),
      questionnaire_id: 12,
      type_id: 7,
      template_id: 6,
    },
  });

  // const options = [
  //   { value: "option1", label: "Chocolate" },
  //   { value: "option2", label: "Chodscolate" },
  //   { value: "option3", label: "dsd" },
  //   // { value: "option4", label: "Chocolate" },
  // ];

  useEffect(() => {
    callThresholdvalues();
  }, []);

  const callThresholdvalues = async () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    // *apicall for createCompanyFeedBackSystem
    const url = `${process.env.REACT_APP_BASE_URL}/showAllThreshold`;
    await axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        console.log(res);
        let arr1 = res.data;
        // console.log("ARR1", arr1);

        var len = arr1.length,
          output = [],
          negative = [];
        // **converting array to object

        for (var i = 0; i < len; i++) {
          if (arr1[i].type == "Positive") {
            output.push({ value: arr1[i].name, label: arr1[i].name });
          } else if (arr1[i].type == "Negative") {
            negative.push({ value: arr1[i].name, label: arr1[i].name });
          }
          // output.push(arr1[i]);
        }

        setNegativeVal(negative);
        setPositiveVal(output);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const imageUpload = (event) => {
    setFileupload(event.target.files[0]);

    setValue({ image: event.target.files[0] });
  };

  console.log("image", fileUpload);
  const submitForm = async (data) => {
    console.log("called");
    console.log("data:", data);

    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    // const url = `${process.env.REACT_APP_BASE_URL}/createCompanyQuestionnaire`;
    // await axios
    //   .post(url, data, {
    //     headers: headers,
    //   })
    //   .then((res) => {
    //     console.log(res);
    //     console.log("Created form !!");
    //     history.push("/fbSystem/table/otherDetail");

    //     // reset();
    //     //  notify();
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     // notifyError();
    //   });
  };

  return (
    <>
      <div className="flex flex-row items-center gap-3">
        <Link to="/fbSystem/table/otherDetail">
          <img src={arrowLeft} alt="leftArrow" />
        </Link>
        <h1 className="text-4xl  px-0 mx-0 font-bold">Add Question</h1>
      </div>
      <form
        className="flex flex-col gap-6 sm:w-full whitespace-nowrap  md:w-2/5 text-base"
        onSubmit={handleSubmit(submitForm)}
      >
        <div className="flex flex-col mt-4">
          <label className="font-bold  text-2xl mb-2">Screen Title</label>
          <input
            class="appearance-none block w-full text-black border rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white"
            id="grid-first-name"
            type="text"
            placeholder="Type Screen Title"
            name="screen_title"
            {...register("screen_title")}
          />
          <p className="text-sm text-red-700 ml-3">
            {" "}
            {errors.screen_title?.message}{" "}
          </p>
        </div>
        <div className="flex flex-col mt-2">
          <label className="font-bold  text-2xl mb-2">Question Title</label>
          <input
            class="appearance-none block w-full text-black border rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white"
            id="grid-first-name"
            type="text"
            placeholder="Type Question Title"
            name="question"
            {...register("question")}
          />
          <p className="text-sm text-red-700 ml-3">
            {" "}
            {errors.question?.message}{" "}
          </p>
        </div>

        <div className="flex flex-col mt-2">
          <label className="font-bold  text-2xl mb-2">Threshold Values</label>
          <p className="font-medium text-base mb-2">Positive Feedback</p>
          <div class="relative mb-5">
            <Controller
              name="positive_thresold"
              control={control}
              render={({ field }) => (
                <Select
                  isMulti
                  // name="colors"
                  // {...register("negative_thresold")}
                  name="positive_thresold"
                  options={positiveVal}
                  className="basic-multi-select"
                />
              )}
            />
            {/* <Select
              {...register("positive_thresold")}
              isMulti
              name="positive_thresold"
              options={positiveVal}
              className="basic-multi-select"
            />
            
            <p className="text-sm text-red-700 ml-3">
              {" "}
              {errors.positive_thresold?.message}{" "}
            </p> */}

            {/* <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div> */}
          </div>
          <p className="font-medium text-base mb-2">Negative Feedback</p>
          <div class="relative mb-2">
            <Controller
              name="negative_thresold"
              control={control}
              render={({ field }) => (
                <Select
                  isMulti
                  // name="colors"
                  // {...register("negative_thresold")}
                  name="negative_thresold"
                  options={negativeVal}
                  className="basic-multi-select"
                />
              )}
            />

            {/* <p className="text-sm text-red-700 ml-3">
              {" "}
              {errors.negative_thresold?.message}{" "}
            </p> */}
          </div>
        </div>
        <div className="flex flex-col mt-2">
          <label className="font-bold  text-2xl mb-2 ">Question Type</label>

          <div class="relative mb-2">
            <select
              class="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-state"
              placeholder="Numeric/AlphaNumeric"
            >
              {/* <option value="" disabled selected>
                        Numeric/AlphaNumeric
                    </option> */}
              <option>Emoji </option>
              <option>Vector</option>
              <option>Polar</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-2">
          <div className="flex flex-row justify-between">
            <label className="font-bold  text-2xl mb-2">Question Vector</label>
            <Switch />
          </div>
          <div className="bg-imageBg w-1/4  rounded-lg">
            <img src={quesVector} alt="quesVector Image" className="md:ml-2" />
          </div>
          <div>
            {/* <button className="bg-changeBtn text-white rounded-lg inline-flex items-center  changeBtn md:w-40 py-3 px-4 mt-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
              Change Vector
            </button> */}
            <input
              type="file"
              name="image"
              {...register("image")}
              // onChange={imageUpload}
              className="block w-full mt-4 text-sm text-slate-500 
                          file:mr-4 file:py-2 file:px-4
                          file:rounded-full file:border-0
                          file:text-sm file:font-semibold
                          file:bg-gray-300 file:text-gray-700
                          hover:file:bg-violet-100
                        "
            />
            <p className="text-sm text-red-700 ml-3">
              {" "}
              {errors.image?.message}{" "}
            </p>
          </div>
        </div>
        <div className="flex flex-col mt-2">
          <label className="font-bold  text-2xl mb-2">Emojis</label>
          <div className="flex flex-wrap  gap-2">
            <div className="bg-imageBg md:w-1/4 p-5 emoBg relative">
              <div className="absolute top-0 right-0">
                <img src={editPencil} alt="edit img" />
              </div>
              <div className="flex justify-center mb-5">
                <img src={happyEmo} alt="emotion image" />
              </div>
              <div className="bg-textBg p-1 w-11/12 absolute bottom-2 left-1  bgText ">
                <p className="text-black text-center md:text-base">Wonderful</p>
              </div>
            </div>
            <div className="bg-imageBg md:w-1/4 p-5 emoBg relative ">
              <div className="absolute top-0 right-0">
                <img src={editPencil} alt="edit img" />
              </div>
              <div className="flex justify-center mb-5">
                <img src={smileEmo} alt="emotion image" />
              </div>
              <div className="bg-textBg p-1 w-11/12 absolute bottom-2 left-1  bgText ">
                <p className="text-black text-center md:text-base">Good</p>
              </div>
            </div>
            <div className="bg-imageBg md:w-1/4 p-5 emoBg relative ">
              <div className="absolute top-0 right-0">
                <img src={editPencil} alt="edit img" />
              </div>
              <div className="flex justify-center mb-5">
                <img src={averageEmo} alt="emotion image" />
              </div>
              <div className="bg-textBg p-1 w-11/12 absolute bottom-2 left-1  bgText ">
                <p className="text-black text-center md:text-base">Average</p>
              </div>
            </div>
            <div className="bg-imageBg md:w-1/4 p-5 emoBg relative ">
              <div className="absolute top-0 right-0">
                <img src={editPencil} alt="edit img" />
              </div>
              <div className="flex justify-center mb-5">
                <img src={sadEmo} alt="emotion image" />
              </div>
              <div className="bg-textBg p-1 w-11/12 absolute bottom-2 left-1  bgText ">
                <p className="text-black text-center md:text-base">Not Good</p>
              </div>
            </div>
            <div className="bg-imageBg md:w-1/4 p-5 emoBg relative ">
              <div className="absolute top-0 right-0">
                <img src={editPencil} alt="edit img" />
              </div>
              <div className="flex justify-center mb-5">
                <img src={angryEmo} alt="emotion image" />
              </div>
              <div className="bg-textBg p-1 w-11/12 absolute bottom-2 left-1  bgText ">
                <p className="text-black text-center md:text-base">Angry</p>
              </div>
            </div>
          </div>
        </div>
        <button
          className="bg-addDetail text-white rounded-lg md:w-40 py-3 px-4 mt-4 mb-3"
          // onClick={submitAddSystem}
        >
          Submit
        </button>

        <div className="flex justify-center md:justify-end text-default lg:flex-row gap-3"></div>
      </form>
    </>
  );
};

export default AddQuestion;
