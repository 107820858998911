import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import arrowLeft from "../../../images/FeedBackSystem/arrowLeft.svg";
import happyEmo from "../../../images/FeedBackSystem/happyEmo.png";
import sadEmo from "../../../images/FeedBackSystem/sadEmo.png";
import angryEmo from "../../../images/FeedBackSystem/angryEmo.png";
import smileEmo from "../../../images/FeedBackSystem/smileEmo.png";
import averageEmo from "../../../images/FeedBackSystem/averageEmo.png";
import editPencil from "../../../images/FeedBackSystem/editPencil.png";
import starVector from "../../../images/WelcomeScreen/starVector.png";
const FblistRequest = () => {
  const formSubmit = () => {};
  return (
    <>
      <div className="flex flex-row items-center gap-3">
        <Link to="/fbList/datatable">
          <img src={arrowLeft} alt="leftArrow" />
        </Link>
        <h1 className="text-4xl  px-0 mx-0 font-bold">Request</h1>
      </div>
      <form
        className="flex flex-col gap-6 mt-4 sm:w-full   md:w-3/5 text-xl"
        onSubmit={formSubmit}
      >
        <div className="flex md:flex-row flex-col mt-5 gap-5">
          <div className="md:w-1/2">
            <label className="text-2xl font-medium">Customer Name</label>
            <input
              class="appearance-none block w-full text-black border rounded py-3 mt-3 px-6 leading-tight focus:outline-none focus:bg-white"
              id="grid-first-name"
              type="text"
              placeholder="Patty O’Furniture"
            />
          </div>
          <div className="md:w-1/2">
            <label className="text-2xl font-medium">Feedback ID</label>
            <input
              class="appearance-none block w-full text-black border rounded py-3 px-6  mt-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-first-name"
              type="text"
              placeholder="NDIA7234N"
            />
          </div>
        </div>
        <div className="flex md:flex-row flex-col mt-5 gap-5">
          <div className="md:w-1/2">
            <label className="font-medium text-2xl ">Feedback Taker ID</label>
            <input
              class="appearance-none block w-full text-black border rounded py-3 mt-3 px-6 leading-tight focus:outline-none focus:bg-white"
              id="grid-first-name"
              type="text"
              placeholder="Patty O’Furniture"
            />
          </div>
          <div className="md:w-1/2">
            <label className="text-2xl font-medium">Feedback Status</label>
            <button className="bg-green-700 uppercase text-white block  py-3 mt-3 px-4 ">
              Positive
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-3 mt-5 w-full bg-reqQuesBg rounded-lg">
          <label className="text-lg font-medium py-3 px-4 ">
            1. How was the Internet?
          </label>
          <div className="flex flex-row md:py-3 md:px-4 px-2 py-2 gap-3">
            <button className="bg-white text-white rounded-lg md:py-3 md:px-4  py-1 px-1">
              <img src={happyEmo} alt="emoImage" />
            </button>
            <button className="bg-white text-white rounded-lg md:py-3 md:px-4 py-1 px-1">
              <img src={smileEmo} alt="emoImage" />
            </button>
            <button className="bg-white text-white rounded-lg md:py-3 md:px-4 py-1 px-1">
              <img src={averageEmo} alt="emoImage" />
            </button>
            <button className="bg-white text-white rounded-lg md:py-3 md:px-4 py-1 px-1">
              <img src={sadEmo} alt="emoImage" />
            </button>
            <button className="bg-white text-white rounded-lg md:py-3 md:px-4 py-1 px-1">
              <img src={angryEmo} alt="emoImage" />
            </button>

            {/* <div className="bg-white rounded-lg md:p-4">
              <img src={happyEmo} alt="emoImage" />
            </div> */}
          </div>
        </div>

        <div className="flex flex-col gap-3 mt-5 w-full bg-reqQuesBg rounded-lg">
          <label className="text-lg font-medium py-3 px-4 ">
            2. How was our service?
          </label>
          <div className="flex flex-col gap-3 py-3 px-4">
            <button className="bg-white btnServiceRes md:w-1/3 py-1 px-4 ">
              Excellent
            </button>
            <button className="bg-white btnServiceRes md:w-1/3 py-1 px-4 ">
              Good
            </button>
            <button className="bg-white btnServiceRes md:w-1/3 py-1 px-4 ">
              Average
            </button>
            <button className="bg-white btnServiceRes md:w-1/3 py-1 px-4 ">
              Worst
            </button>
          </div>
        </div>

        <div className="flex flex-col gap-3 mt-5 w-full bg-reqQuesBg rounded-lg">
          <label className="text-lg font-medium py-3 px-4 ">
            3. How Would you rate our overall experience
          </label>
          <div className="flex flex-xol md:py-3 md:px-4 px-2 py-2 gap-3">
            <button className="bg-white text-white rounded-lg md:py-3 md:px-4  py-1 px-1">
              <img src={starVector} alt="emoImage" />
            </button>
            <button className="bg-white text-white rounded-lg md:py-3 md:px-4 py-1 px-1">
              <img src={starVector} alt="emoImage" />
            </button>
            <button className="bg-white text-white rounded-lg md:py-3 md:px-4 py-1 px-1">
              <img src={starVector} alt="emoImage" />
            </button>
            <button className="bg-white text-white rounded-lg md:py-3 md:px-4 py-1 px-1">
              <img src={starVector} alt="emoImage" />
            </button>
            <button className="bg-white text-white rounded-lg md:py-3 md:px-4 py-1 px-1">
              <img src={starVector} alt="emoImage" />
            </button>

            {/* <div className="bg-white rounded-lg md:p-4">
              <img src={happyEmo} alt="emoImage" />
            </div> */}
          </div>
        </div>
        <div>
          <button className="bg-lightGrey text-black hover:bg-gray-400 rounded-lg py-3 px-4">
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default FblistRequest;
