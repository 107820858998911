import React, { useState } from "react";
import avatar from "../images/sidebar/avatar.svg";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
const TopBar = () => {
  const history = useHistory();
  const [dropdown, setDropdown] = useState(false);
  const showDropFunc = () => {
    setDropdown(!dropdown);
  };

  // console.log("token", localStorage.getItem("token"));
  const token = localStorage.getItem("token");
  const logout = async () => {
    var config = {
      method: "post",
      url: "http://fmb.digitaezonline.com/api/logout",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));

        history.push("/");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <>
      <div className="flex justify-end shadow-xl p-3">
        {/* <p classNameName="text-base text-black font-bold">John Doe</p>
        <div className="avatar ">
          <div className=" rounded-full">
            <img src={avatar} />
          </div>
        </div> */}
        <div className="flex items-center ">
          <div className="text-base">
            <button className="text-base text-black font-bold leading-none mr-2">
              {localStorage.getItem("name")}
            </button>
          </div>
          <img
            className="w-10 h-10 rounded-full "
            src={avatar}
            alt="Avatar of Jonathan Reinink"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
            onClick={showDropFunc}
            cursor="pointer"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
          {/* Dropdown Modals */}

          {/* Dropdown Modals */}
        </div>
      </div>
      {dropdown ? (
        <>
          <div className="flex justify-end relative">
            <div className="absolute bottom-0 right-1 top-0">
              <div
                id="dropdown"
                className="z-50 bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700 "
              >
                <ul className="py-1 text-sm text-gray-700 dark:text-gray-200">
                  <li>
                    <a className="flex flex-row px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                        />
                      </svg>

                      {/* <span className="ml-2 z-40" onClick={logout}> */}
                      <Link to={"/"} className="ml-2" onClick={logout}>
                        {" "}
                        Logout
                      </Link>
                      {/* </span> */}
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="#"
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white "
                    >
                      Action
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
export default TopBar;
