import React, { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import MaterialTable from "material-table";
import tableIcons from "../../../components/MaterialTableIcons";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Paper } from "@material-ui/core";
import axios from "axios";
import DeleteTableBtn from "../../../components/DeleteTableBtn";
import EditTableBtn from "../../../components/EditTableBtn";
const OtherDetailTable = () => {
  const handlePageClick = () => {};

  const columns = [
    { title: "S.NO", field: "company_questionnaire_id" },
    { title: "QUESTION TITLE", field: "question" },
    { title: "QUESTION ID", field: "questionnaire_id" },
    { title: "QUESTION TYPE", field: "types_name" },

    // *TODO Created date filed is missing in API
    // {
    //   title: "CREATED date",
    //   field: "created at",
    //   render: (rowData) => rowData.created_at.split("T").slice(0, 1),
    // },
  ];

  const action1 = "Delete";
  const action2 = "View";

  const yourRef = React.createRef();
  const deleteRow = async (scopedRef, rowId) => {
    console.log("inside delete row", rowId);
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    const url = `${process.env.REACT_APP_BASE_URL}/api/deleteQuestionnaire/${rowId}`;
    await axios
      .delete(url, {
        headers: headers,
      })
      .then((res) => {
        console.log(res);
        console.log("row deleted !!");
        scopedRef.onQueryChange();
      })
      .catch((error) => {
        console.log(error);
        // notifyError();
      });
  };

  return (
    <>
      <>
        <div className="flex flex-wrap gap-6 flex-row w-100 mt-3 justify-end items-center">
          <div>
            <Link to="/fbSystem/table/addQuestion">
              <button className="gradientColor  text-white w-40 py-3 px-4 ">
                Add Question
              </button>
            </Link>
          </div>
        </div>
        <MaterialTable
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          title=""
          icons={tableIcons}
          columns={columns}
          options={{
            headerStyle: {
              fontWeight: "bold",
              fontSize: "15px",
              textTransform: "uppercase",
            },
            search: true,
            actionsColumnIndex: -1,
            paging: false,
          }}
          tableRef={yourRef}
          actions={[
            {
              icon: () => <DeleteTableBtn text={action1} />,
              tooltip: "Delete",
              onClick: async (event, rowData) => {
                console.log("ques id", rowData.questionnaire_id);
                // deleteRow(rowData.questionnaire_id, scopedRef);
                const scopedRef = yourRef.current;
                const headers = {
                  Authorization: `Bearer +${localStorage.getItem("token")}`,
                };
                var config = {
                  method: "delete",
                  url: `${process.env.REACT_APP_BASE_URL}/api/deleteQuestionnaire/${rowData.questionnaire_id}`,
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                };

                const url = `${process.env.REACT_APP_BASE_URL}/deleteQuestionnaire/${rowData.questionnaire_id}`;
                // axios(config)
                //   .then(function (response) {
                //     console.log(JSON.stringify(response.data));
                //     console.log("row deleted !!");
                //     scopedRef.onQueryChange();
                //   })
                //   .catch(function (error) {
                //     console.log(error);
                //   });
                await axios
                  .delete(url, {
                    headers: headers,
                  })
                  .then((res) => {
                    console.log(res);
                    console.log("row deleted !!");
                    scopedRef.onQueryChange();
                  })
                  .catch((error) => {
                    console.log(error);
                    console.log("errorr");
                    // notifyError();
                  });
              },
            },
            {
              icon: () => <EditTableBtn text={action2} />,
              tooltip: "Edit",
              onClick: (event, rowData) => {
                console.log("val", rowData.company_feedbacks_id);
              },
            },

            // rowData => ({
            //   icon: 'delete',
            //   tooltip: 'Delete User',
            //   onClick: (event, rowData) => confirm("You want to delete " + rowData.name),
            //   disabled: rowData.birthYear < 2000
            // })
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              var myHeaders = new Headers();
              myHeaders.append("Accept", "application/json");
              myHeaders.append(
                "Authorization",
                `Bearer ${localStorage.getItem("token")}`
              );

              var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };
              let url = `${
                process.env.REACT_APP_BASE_URL
              }/getCompanyQuestionnaire/${localStorage.getItem("company_id")}`;
              // url += "page=" + query.page + 1;
              // url += "&page=" + (query.page + 1);
              // console.log("page", parseInt(query.page));
              fetch(url, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                  console.log("apicall", result);
                  // setData(result.data.data);
                  console.log(result.data);
                  console.log("Querry", query);
                  resolve({
                    data: result,
                    page: query.page,
                    totalCount: query.totalCount,
                  });
                })
                .catch((error) => console.log("error", error));
            })
          }
        />
      </>
    </>
  );
};

export default OtherDetailTable;
