import React from "react";
import Wrapper from "../../../components/Wrapper";

import companylogo from "../../../images/WelcomeScreen/companyLogo.png";
import welcome from "../../../images/WelcomeScreen/welcome.png";
const WelcomeScreen = () => {
  return (
    <>
      <div className="flex flex-wrap gap-4">
        <div className="md:w-2/5 ">
          <h1 className="text-4xl  px-0 mx-0 font-medium">Company Logo</h1>
          <div className="bg-imageBg imageWrapper  md:w-2/5 py-14  md:px-6 px-9    mt-4">
            <img src={companylogo} alt="companylogo" />
          </div>
          <div>
            <button className="bg-lightGrey text-black rounded-lg inline-flex items-center  py-3 px-4 mt-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
              Change Picture
            </button>
          </div>
        </div>
        <div className="md:w-2/5 ">
          <h1 className="text-4xl  px-0 mx-0 font-medium">Screen Text</h1>
          <textarea class="resize textArea rounded-md bg-imageBg border mt-4 w-full"></textarea>
        </div>
        <div className="md:w-4/5 mt-4">
          <h1 className="text-4xl  px-0 mx-0 font-medium">Screen Vector</h1>
          <div className="bg-imageBg imageWrapper  md:w-2/5 py-14  md:px-6  px-9   mt-4">
            <img src={welcome} alt="companylogo" />
          </div>
          <div>
            <button className="bg-lightGrey text-black rounded-lg inline-flex items-center  py-3 px-4 mt-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
              Change Picture
            </button>
          </div>
          <div className="flex flex-row mt-4">
            <button className=" bg-cancelBtn text-white rounded-lg md:w-40 py-3 px-4 ">
              Discard
            </button>
            <button className="bg-greenBtn text-white rounded-lg md:w-40 py-3 px-4 ml-2">
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomeScreen;
