import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../images/FmbLogo.png";

const ResetPw = () => {
  const history = useHistory();
  return (
    <div className="flex flex-col gap-3 mt-48 justify-content-center items-center">
      <div className="text-center">
        <h1 className="text-3xl font-bold">Reset Your Password</h1>
        <h1 className="text-xl font-light">Enter your New Password</h1>
      </div>
      <form className=" md:w-1/4" action="">
        <div className="flex flex-col">
          <input
            type="email"
            placeholder="Enter New Email"
            className="px-5 py-3 rounded-xl input-field bg-white rounded-0 col-md-3 mt-3"
          />
        </div>

        <div className="flex flex-col">
          <input
            type="password"
            placeholder="Enter New Password"
            className="px-5 py-3 rounded-xl input-field bg-white rounded-0 col-md-3 mt-3"
          />
        </div>

        <div className="flex flex-col justify-center text-center items-center">
          <Link
            to="/su/dashboard"
            onClick={() => {
              history.push("/su/dashboard");
            }}
            className="sign-in py-3 px-10 mt-7 text-center text-white rounded-xl"
          >
            Confirm
          </Link>
        </div>
      </form>
    </div>
  );
};

export default ResetPw;
