import Faq from "react-faq-component";
import SearchBar from "./SearchBar";
import image from "../images/sidebar/building.svg";
import Collapsible from "react-collapsible";

const FaqComp = () => {
  return (
    <div className="border rounded border-gray-300 p-6">
      <div className="flex flex-col md:flex-row justify-between gap-4 items-center">
        <h2 className="text-lg font-bold">How does this portal work?</h2>
        <div className="flex flex-row gap-3">
          <a className="redAction-btn rounded-lg py-1 px-3 text-white" href="">
            Delete
          </a>
          <a
            className="greenAction-btn rounded-lg py-1 px-3 text-white"
            href=""
          >
            Edit
          </a>
        </div>
      </div>
      <p className="mt-3">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorem fugit
        quam eligendi est neque asperiores deleniti alias, facere aspernatur.
        Qui esse nostrum soluta, asperiores quos distinctio vero illum
        aspernatur autem.
      </p>
    </div>
  );
};

export default FaqComp;
