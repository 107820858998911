import React from "react";
import { useHistory } from "react-router-dom";
import { Redirect, Route } from "react-router-dom";
import WrapperSu from "../components/WrapperSu";

function Admin({ component: Component, ...restOfProps }) {
  let isAuthenticated = null;
  const history = useHistory;
  console.log("hellowwww");
  if (localStorage.getItem("token") == null || undefined) {
    console.log(isAuthenticated);
    isAuthenticated = false;
  } else if (localStorage.getItem("role") == "admin") {
    isAuthenticated = true;
    console.log("Auth in Admin", isAuthenticated);
  }

  return (
    <>
      {console.log("route state" + isAuthenticated)}
      <Route
        {...restOfProps}
        render={(props) =>
          isAuthenticated ? (
            <WrapperSu>
              {" "}
              <Component {...props} />{" "}
            </WrapperSu>
          ) : (
            <Redirect to="/" />
          )
        }
      />
    </>
  );
}
export default Admin;
