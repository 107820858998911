import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import MaterialTable from "material-table";
import tableIcons from "../../../components/MaterialTableIcons";
import { Paper } from "@material-ui/core";
import FindInPageOutlinedIcon from "@material-ui/icons/FindInPageOutlined";
import axios from "axios";
import DeleteIcon from "@material-ui/icons/Delete";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import Modal from "../../../components/Modal";

const IndustryTable = () => {
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [industryName, setIndustryName] = useState("");

  const closeEditModal = () => {
    setTimeout(() => {
      setEditModal(false);
    }, 10);
  };

  const closeModal = () => {
    setTimeout(() => {
      setModal(false);
    }, 10);
  };

  const history = useHistory();
  //   const {
  //     register,
  //     handleSubmit,
  //     reset,
  //     setValue,
  //     formState: { errors },
  //   } = useForm({
  //     resolver: yupResolver(FeedbabckSystem),
  //   });

  //   const submitForm = async (data) => {
  //     console.log("data:", data);
  //     let data2 = {
  //       company_id: localStorage.getItem("company_id"),
  //     };
  //     const formval = { ...data, ...data2 };
  //     const headers = {
  //       Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     };
  //     // *apicall for createCompanyFeedBackSystem
  //     const url = `${process.env.REACT_APP_BASE_URL}/createCompanyFeedBackSystem`;
  //     await axios
  //       .post(url, formval, {
  //         headers: headers,
  //       })
  //       .then((res) => {
  //         console.log(res);
  //         console.log("Success !!");
  //         setFbsystemID(res.data.CompanyFeedBackSystemId);
  //         // reset();
  //         //  notify();
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         // notifyError();
  //       });

  //     // *apicall for FeedbackID
  //     const url2 = `${process.env.REACT_APP_BASE_URL}/createFeedBackId`;
  //     await axios
  //       .post(url2, formval, {
  //         headers: headers,
  //       })
  //       .then((res) => {
  //         console.log(res);
  //         console.log("Success !!");
  //         setFbID(res.data.FeedBackId);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });

  //     // *apicall for createCompanyFeedBackSystemPivot
  //     if (fbSystemID && fbID) {
  //       console.log("fbsyste and systemID hai");
  //       const url3 = `${process.env.REACT_APP_BASE_URL}/createCompanyFeedBackSystemPivot`;
  //       await axios;
  //       const val = {
  //         company_id: `${localStorage.getItem("company_id")}`,
  //         company_feed_back_system_id: fbSystemID,
  //         company_feedbacks_id: fbID,
  //       };
  //       console.log("VAL PVOT", val);

  //       await axios
  //         .post(url3, val, {
  //           headers: headers,
  //         })
  //         .then((res) => {
  //           console.log(res);
  //           console.log("api3 !!");
  //           history.push("/fbSystem/table/otherDetail");
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     }
  //   };

  const columns = [
    { title: "INDUSTRY NAME", field: "name" },
    { title: "CREATED DATE", field: "created_at" },
    { title: "INDUSTRY ID", field: "id" },
  ];

  return (
    <>
      <div className="flex flex-wrap gap-6 flex-row w-100 mt-3 justify-end items-center mb-4">
        <div>
          <button
            className="gradientColor  text-white w-40 py-3 px-4 "
            onClick={() => setModal(true)}
          >
            Add Industry
          </button>
        </div>
      </div>

      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        title={null}
        icons={tableIcons}
        columns={columns}
        options={{
          headerStyle: {
            fontWeight: "bold",
            fontSize: "15px",
            textTransform: "uppercase",
          },
          search: true,
          actionsColumnIndex: -1,
        }}
        actions={[
          {
            icon: () => <DeleteIcon />,
            tooltip: "Delete",
            onClick: (event, rowData) => {
              console.log("val", rowData.id);
            },
          },
          {
            icon: () => <EditIcon />,
            tooltip: "Edit",
            onClick: (event, rowData) => {
              setEditModal(true);
              setIndustryName(rowData.name);
              console.log("val", rowData.id);
            },
          },
          // rowData => ({
          //   icon: 'delete',
          //   tooltip: 'Delete User',
          //   onClick: (event, rowData) => confirm("You want to delete " + rowData.name),
          //   disabled: rowData.birthYear < 2000
          // })
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append(
              "Authorization",
              `Bearer ${localStorage.getItem("token")}`
            );

            var requestOptions = {
              method: "GET",
              headers: myHeaders,
              redirect: "follow",
            };
            let url = `${process.env.REACT_APP_BASE_URL}/showIndustry?`;
            url += `page=${query.page + 1}`;
            // url += "page=" + query.page + 1;
            // url += "&page=" + (query.page + 1);
            // console.log("page", parseInt(query.page));
            fetch(url, requestOptions)
              .then((response) => response.json())
              .then((result) => {
                console.log("apicall", result);
                // setData(result.data.data);
                console.log(result.data);
                console.log("Querry", query);
                console.log("current pg", result.data.current_page);

                resolve({
                  data: result.data,
                  page: query.page,
                  totalCount: result.data.total,
                });
              })
              .catch((error) => console.log("error", error));
          })
        }
      />
      {/* modal */}
      <Modal show={modal} close={closeModal}>
        <div className="flex flex-col gap-3">
          <h1 className="text-lg font-bold">Industry Name</h1>
          <input
            type="text"
            className="h-12 w-full pl-4 pr-4  z-0 focus:shadow focus:outline-none border text-sm border-light-grey rounded "
            placeholder="Enter Name"
          />
          <div className="grid grid-cols-2  justify-between gap-3">
            <button className="redbutton text-white rounded-lg grow py-2 px-4 ">
              Cancel
            </button>
            <button className="greybutton text-white rounded-lg grow py-2 px-4 ">
              Save
            </button>
          </div>
        </div>
      </Modal>

      <Modal show={editModal} close={closeEditModal}>
        <div className="flex flex-col gap-3 ">
          <h1 className="text-lg font-bold">Edit Industry Name</h1>
          <input
            type="text"
            className="h-12 w-full pl-4 pr-4  z-0 focus:shadow focus:outline-none border text-sm border-light-grey rounded "
            placeholder={industryName}
          />
          <div className="grid grid-cols-2  justify-between gap-3">
            <button className="redbutton text-white rounded-lg grow py-2 px-4 ">
              Cancel
            </button>
            <button className="greybutton text-white rounded-lg grow py-2 px-4 ">
              Save
            </button>
          </div>
        </div>
      </Modal>
      {/* modal */}
    </>
  );
};

export default IndustryTable;
