import Table from "../../../components/Table";
import Wrapper from "../../../components/Wrapper";
import ActiveBrandsTable from "../../../components/ActiveBrandsTable";
import InActiveBrandsTable from "./InActiveBrandsTable";

const InactiveBrands = () => {
  return <InActiveBrandsTable />;
};

export default InactiveBrands;
