import * as yup from "yup";
export const TakerSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  // .matches(
  //   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
  //   "Your password must be more than 8 characters long, should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric and 1 special character"
  // ),
  //   company_id: yup
  //     .string()

  //     .required("ID is required"),
});
