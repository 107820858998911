import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import logo from "../images/sidebar/fmbLogo.svg";
import dashboard from "../images/sidebar/dashboard.svg";
import addons from "../images/sidebar/addOns.svg";
import form from "../images/sidebar/form.svg";
import building from "../images/sidebar/building.svg";
import payment from "../images/sidebar/payment.svg";
import help from "../images/sidebar/help.svg";
import brands from "../images/sidebar/brands.svg";

import { NavLink } from "react-router-dom";
// import Dashboard from "../pages/Dashboard/Dashboard";

const SideBar = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [brandsDrop, setBrandDrop] = useState(false);
  const [feedDrop, setFeedDrop] = useState(false);
  const [helpDrop, setHelpDrop] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const BrandFunc = () => {
    setBrandDrop(!brandsDrop);
  };

  const FeedFunc = () => {
    setFeedDrop(!feedDrop);
  };

  const HelpFunc = () => {
    setHelpDrop(!helpDrop);
  };

  const display = () => {
    //console.log("Pressed");
    setShowSidebar(!showSidebar);
    setMobileView(true);
  };

  //For keeping dropdowns open on page render

  let location = useLocation();

  useEffect(() => {
    //For Brands
    if (
      location.pathname == "/su/brands/active-brands" ||
      location.pathname == "/su/brands/inactive-brands" ||
      location.pathname == "/su/brands/brand-requests"
    ) {
      console.log("Matched !!");
      setBrandDrop(true);
    }

    //For Feedback System
    if (
      location.pathname == "/su/feedback-system/default-questions" ||
      location.pathname == "/su/feedback-system/question-vectors" ||
      location.pathname == "/su/feedback-system/question-emoji" ||
      location.pathname == "/su/feedback-system/vector-add-req"
    ) {
      console.log("Matched !!");
      setFeedDrop(true);
    }

    if (
      location.pathname == "/su/help/faqs" ||
      location.pathname == "/su/help/help-requests" ||
      location.pathname == "/su/help/suggestion-requests" ||
      location.pathname == "/su/help/complain-requests"
    ) {
      console.log("Matched !!");
      setHelpDrop(true);
    }

    if (mobileView) {
      // console.log("location change in mobileView");
      setShowSidebar(true);
    }
  }, [location]);

  return (
    <>
      {/* mobileView */}
      <div className="flex  flex-row-reverse md:hidden">
        {/* logo */}

        {/* mobile menu button */}
        <button
          className="mobile-menu-button justify-start p-4 focus:outline-none focus:bg-gray-100"
          onClick={display}
        >
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>

      {/* MainSideBar */}
      <div
        className={
          showSidebar
            ? "z-50 overflow-y-auto scrollbar sidebar nav-logo bg-sidebar sideBar_position -translate-x-full text-white w-60 space-y-6 py-7 absolute inset-y-0 left-0 transform  md:relative md:translate-x-0 transition duration-200 ease-in-out"
            : "z-50 overflow-y-auto scrollbar sidebar nav-logo bg-sidebar text-white w-60 space-y-6 py-7   absolute inset-y-0 left-0 transform  md:relative md:translate-x-0 transition duration-200 ease-in-out "
        }
      >
        <div className="flex w-full  justify-center">
          <img src={logo} className="mb-3 " alt="FMB Logo" />
        </div>

        <ul className="space-y-2">
          <li className="hover:bg-sideHover">
            <NavLink
              to="/su/dashboard"
              activeClassName="active"
              className="flex py-2.5  items-center   transition duration-200   text-sm"
            >
              <div className="ml-3 flex items-center">
                <img src={dashboard} alt="sidebar icons" />
                <span className="flex-1 ml-3 text-left text-lg whitespace-nowrap">
                  Dashboard
                </span>
              </div>
            </NavLink>
          </li>
          <li className="hover:bg-sideHover">
            <NavLink
              to="/su/industries"
              activeClassName="active"
              className="flex py-2.5  items-center   transition duration-200   text-sm"
            >
              <div className="ml-3 flex items-center">
                <img src={building} alt="sidebar icons" />
                <span className="flex-1 ml-3 text-left text-lg whitespace-nowrap">
                  Industries
                </span>
              </div>
            </NavLink>
          </li>
          <li className="hover:bg-sideHover">
            {/* <NavLink
              to="/su/wrapper"
              activeClassName="active"
              className="flex py-2.5 px-4 items-center  rounded transition duration-200   text-sm"
            >
              <img src={hellp} alt="sidebar icons" />
              <span className="flex-1 ml-3 text-left text-lg whitespace-nowrap">
                Help
              </span>
            </NavLink> */}
            <div
              className={
                brandsDrop
                  ? "py-2.5 bg-dropDown transition duration-200 text-sm"
                  : "py-2.5 transition duration-200   text-sm"
              }
            >
              <div className="flex items-center ml-3">
                <img src={brands} alt="sidebar icons" />
                <button
                  className="flex-1 block ml-3 text-left text-lg whitespace-nowrap"
                  onClick={BrandFunc}
                >
                  Brands
                </button>
              </div>
              {brandsDrop ? (
                <>
                  <ul id="dropdown-example" className=" py-2 space-y-2  ">
                    <li className="hover:bg-dropDown">
                      <NavLink
                        to="/su/brands/active-brands"
                        activeClassName="active"
                        className="flex py-2.5  items-center  transition duration-200   text-sm"
                      >
                        <div className="ml-7 flex items-center">
                          <span className="ml-5  text-lg">Active Brands</span>
                        </div>
                      </NavLink>
                    </li>
                    <li className="hover:bg-dropDown">
                      <NavLink
                        to="/su/brands/inactive-brands"
                        activeClassName="active"
                        className="flex py-2.5 items-center  transition duration-200   text-sm"
                      >
                        <div className="ml-7 flex items-center">
                          <span className="ml-5  text-lg">Inactive Brands</span>
                        </div>
                      </NavLink>
                    </li>
                    <li className="hover:bg-dropDown">
                      <NavLink
                        to="/su/brands/brand-requests"
                        activeClassName="active"
                        className="flex py-2.5 items-center  transition duration-200   text-sm"
                      >
                        <div className="ml-7 flex items-center">
                          <span className="ml-5  text-lg">Brand Requests</span>
                        </div>
                      </NavLink>
                    </li>
                  </ul>
                </>
              ) : null}
            </div>
          </li>
          <li className="hover:bg-sideHover">
            {/* <NavLink
              to="/su/wrapper"
              activeClassName="active"
              className="flex py-2.5 px-4 items-center  rounded transition duration-200   text-sm"
            >
              <img src={hellp} alt="sidebar icons" />
              <span className="flex-1 ml-3 text-left text-lg whitespace-nowrap">
                Help
              </span>
            </NavLink> */}
            <div
              className={
                feedDrop
                  ? "py-2.5 bg-dropDown transition duration-200 text-sm"
                  : "py-2.5 transition duration-200   text-sm"
              }
            >
              <div className="flex items-center ml-3">
                <img src={form} alt="sidebar icons" />
                <button
                  className="flex-1 block ml-3 text-left text-lg whitespace-nowrap"
                  onClick={FeedFunc}
                >
                  Feedback System
                </button>
              </div>
              {feedDrop ? (
                <>
                  <ul id="dropdown-example" className=" py-2 space-y-2  ">
                    <li className="hover:bg-dropDown">
                      <NavLink
                        to="/su/feedback-system/default-questions"
                        activeClassName="active"
                        className="flex py-2.5  items-center  transition duration-200   text-sm"
                      >
                        <div className="ml-7 flex items-center">
                          <span className="ml-5  text-lg">
                            Default Questions
                          </span>
                        </div>
                      </NavLink>
                    </li>
                    <li className="hover:bg-dropDown">
                      <NavLink
                        to="/su/feedback-system/question-vectors"
                        activeClassName="active"
                        className="flex py-2.5 items-center  transition duration-200   text-sm"
                      >
                        <div className="ml-7 flex items-center">
                          <span className="ml-5  text-lg">
                            Question Vectors
                          </span>
                        </div>
                      </NavLink>
                    </li>
                    <li className="hover:bg-dropDown">
                      <NavLink
                        to="/su/feedback-system/question-emoji"
                        activeClassName="active"
                        className="flex py-2.5 items-center  transition duration-200   text-sm"
                      >
                        <div className="ml-7 flex items-center">
                          <span className="ml-5  text-lg">Question Emojis</span>
                        </div>
                      </NavLink>
                    </li>
                    <li className="hover:bg-dropDown">
                      <NavLink
                        to="/su/feedback-system/vector-add-req"
                        activeClassName="active"
                        className="flex py-2.5 items-center  transition duration-200   text-sm"
                      >
                        <div className="ml-7 flex items-center">
                          <span className="ml-5  text-lg">
                            Vector Addition Requests
                          </span>
                        </div>
                      </NavLink>
                    </li>
                  </ul>
                </>
              ) : null}
            </div>
          </li>
          <li className="hover:bg-sideHover">
            <NavLink
              to="/su/add-ons"
              activeClassName="active"
              className="flex py-2.5  items-center   transition duration-200   text-sm"
            >
              <div className="ml-3 flex items-center">
                <img src={addons} alt="sidebar icons" />
                <span className="flex-1 ml-3 text-left text-lg whitespace-nowrap">
                  Add ons
                </span>
              </div>
            </NavLink>
          </li>
          {/* <li className="hover:bg-sideHover">
            <NavLink
              to="/su/"
              activeClassName="active"
              className="flex py-2.5 px-4 items-center   transition duration-200   text-sm"
            >
              <img src={vectorRequest} alt="sidebar icons" />
              <span className="flex-1 ml-3 text-left text-lg whitespace-normal ">
                Vector Addition Request
              </span>
            </NavLink>
          </li> */}
          <li className="hover:bg-sideHover">
            {/* <NavLink
              to="/su/wrapper"
              activeClassName="active"
              className="flex py-2.5 px-4 items-center  rounded transition duration-200   text-sm"
            >
              <img src={hellp} alt="sidebar icons" />
              <span className="flex-1 ml-3 text-left text-lg whitespace-nowrap">
                Help
              </span>
            </NavLink> */}
            <div
              className={
                helpDrop
                  ? "py-2.5 bg-dropDown transition duration-200 text-sm"
                  : "py-2.5 transition duration-200   text-sm"
              }
            >
              <div className="flex items-center ml-3">
                <img src={help} alt="sidebar icons" />
                <button
                  className="flex-1 block ml-3 text-left text-lg whitespace-nowrap"
                  onClick={HelpFunc}
                >
                  Help
                </button>
              </div>
              {helpDrop ? (
                <>
                  <ul id="dropdown-example" className=" py-2 space-y-2  ">
                    <li className="hover:bg-dropDown">
                      <NavLink
                        to="/su/help/faqs"
                        activeClassName="active"
                        className="flex py-2.5  items-center  transition duration-200   text-sm"
                      >
                        <div className="ml-7 flex items-center">
                          <span className="ml-5  text-lg">FAQs</span>
                        </div>
                      </NavLink>
                    </li>
                    <li className="hover:bg-dropDown">
                      <NavLink
                        to="/su/help/help-requests"
                        activeClassName="active"
                        className="flex py-2.5 items-center  transition duration-200   text-sm"
                      >
                        <div className="ml-7 flex items-center">
                          <span className="ml-5  text-lg">Help Requests</span>
                        </div>
                      </NavLink>
                    </li>
                    <li className="hover:bg-dropDown">
                      <NavLink
                        to="/su/help/suggestion-requests"
                        activeClassName="active"
                        className="flex py-2.5 items-center  transition duration-200   text-sm"
                      >
                        <div className="ml-7 flex items-center">
                          <span className="ml-5  text-lg">
                            Suggestions Requests
                          </span>
                        </div>
                      </NavLink>
                    </li>
                    <li className="hover:bg-dropDown">
                      <NavLink
                        to="/su/help/complain-requests"
                        activeClassName="active"
                        className="flex py-2.5 items-center  transition duration-200   text-sm"
                      >
                        <div className="ml-7 flex items-center">
                          <span className="ml-5  text-lg">
                            Complains Requests
                          </span>
                        </div>
                      </NavLink>
                    </li>
                  </ul>
                </>
              ) : null}
            </div>
          </li>
          <li className="hover:bg-sideHover">
            <NavLink
              to="/su/payment-req"
              activeClassName="active"
              className="flex py-2.5  items-center   transition duration-200   text-sm"
            >
              <div className="ml-3 flex items-center">
                <img src={payment} alt="sidebar icons" />
                <span className="flex-1 ml-3 text-left text-lg whitespace-nowrap">
                  Payment Requests
                </span>
              </div>
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SideBar;
