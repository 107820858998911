import React from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import SearchBar from "../../../components/SearchBar";
import Table from "../../../components/Table";
import TableFooter from "../../../components/TableFooter";
import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import tableIcons from "../../../components/MaterialTableIcons";
import DeleteTableBtn from "../../../components/DeleteTableBtn";
import EditTableBtn from "../../../components/EditTableBtn";
const FblistTable = () => {
  const handlePageClick = () => {};
  const columns = [
    { title: "S.No", field: "name" },
    { title: "Customer name", field: "company_feedbacks_id" },
    { title: "Feedback Taker ID", field: "company_feedbacks_id" },
    { title: "Feedback status", field: "company_feedbacks_id" },

    // { title: "ACTIONS", filed: "action" },
  ];

  const action = "View";
  return (
    <>
      {/* * TODO:  When get correct api then on clicking view btn route to FBlist Request page  */}
      <div className="mt-4">
        <MaterialTable
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          title=""
          icons={tableIcons}
          columns={columns}
          options={{
            headerStyle: {
              fontWeight: "bold",
              fontSize: "15px",
              textTransform: "uppercase",
            },
            // searchFieldAlignment: "left",
            search: true,
            actionsColumnIndex: -1,
          }}
          actions={[
            // {
            //   icon: () => <DeleteTableBtn text={action} />,
            //   tooltip: "Delete",
            //   onClick: (event, rowData) => {
            //     console.log("val", rowData.id);
            //   },
            // },
            {
              icon: () => <EditTableBtn text={action} />,
              tooltip: "Edit",
              onClick: (event, rowData) => {
                console.log("val", rowData.company_feedbacks_id);
              },
            },
            // rowData => ({
            //   icon: 'delete',
            //   tooltip: 'Delete User',
            //   onClick: (event, rowData) => confirm("You want to delete " + rowData.name),
            //   disabled: rowData.birthYear < 2000
            // })
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              var myHeaders = new Headers();
              myHeaders.append("Accept", "application/json");
              myHeaders.append(
                "Authorization",
                `Bearer ${localStorage.getItem("token")}`
              );

              var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };
              let url = `${
                process.env.REACT_APP_BASE_URL
              }/getCompanyFeedbackSystemPivot/${localStorage.getItem(
                "company_id"
              )}?`;
              url += `page=${query.page + 1}`;
              // url += "page=" + query.page + 1;
              // url += "&page=" + (query.page + 1);
              // console.log("page", parseInt(query.page));
              fetch(url, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                  console.log("apicall", result);
                  // setData(result.data.data);
                  console.log(result.data);
                  // console.log("Querry", query);
                  // console.log("current pg", result.data.current_page);

                  resolve({
                    data: result.data.data,
                    page: query.page,
                    totalCount: result.data.total,
                  });
                })
                .catch((error) => console.log("error", error));
            })
          }
        />
      </div>
    </>
  );
};

export default FblistTable;
