import DashboardCards from "../../../components/DashboardCards";
import Wrapper from "../../../components/Wrapper";

const Dashboard = () => {
  return (
    <div>
      <div className="flex  flex-col md:flex-row md:items-center gap-5 flex-wrap">
        <DashboardCards background={"dash-green"} />
        <DashboardCards background={"dash-blue"} />
        <DashboardCards background={"dash-brown"} />
        <DashboardCards background={"dash-red"} />
        <DashboardCards background={"dash-yellow"} />
        <DashboardCards background={"dash-lightblue"} />
      </div>
    </div>
  );
};

export default Dashboard;
